// import logo from "./logo.svg"
// import * as Styles from "../../components/Footer/Footer.styles"

export const vars_scc = {
  // slug: "scc",
  title: "Services Directory",
  tagline: "Services for adults, families and people with SEND",
  contactEmail: "info@southampton.gov.uk",
  contactPhone: "023 8083 3000",
  serviceHomepageUrl: "https://www.southampton.gov.uk",
  organisation: "Southampton City Council",
  organisationUrl: "https://www.southampton.gov.uk/",
  beta: false,
  mapSwitchSmall: true,
  serviceCard: {
    hideCategories: true,
  },
  itemsPerPage: 20,
  cookiesDisabledMessage:
    "Please note, cookies are needed for site functionality such as interactive maps and location autocomplete.",
  // cookieMessage: (
  //   <>
  //     <p>
  //       We use{" "}
  //       <a href="https://www.buckinghamshire.gov.uk/about/cookies/">
  //         cookies to collect information
  //       </a>{" "}
  //       about how you use the Buckinghamshire Council website and directories.
  //     </p>
  //     <p>
  //       We use this information to improve the website, directories and our
  //       services.{" "}
  //       <a href="https://www.buckinghamshire.gov.uk/about/cookies/">
  //         How to manage cookies.
  //       </a>
  //     </p>
  //   </>
  // ),
  cookieName: "CookieControl",
  // cookieCallback: () => {
  //   console.log("cookieCallback")

  //   // Google Tag Manager - new GA4
  //   ;(function (w, d, s, l, i) {
  //     w[l] = w[l] || []
  //     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l !== "dataLayer" ? "&l=" + l : ""
  //     j.async = true
  //     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  //     f.parentNode.insertBefore(j, f)
  //   })(window, document, "script", "dataLayer", "GTM-W9X4P7B")

  //   // Google Tag Manager - original - remove after July 2023
  //   ;(function (w, d, s, l, i) {
  //     w[l] = w[l] || []
  //     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l !== "dataLayer" ? "&l=" + l : ""
  //     j.async = true
  //     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  //     f.parentNode.insertBefore(j, f)
  //   })(window, document, "script", "dataLayer", "GTM-NHRXC57")

  //   // Siteimprove
  //   ;(function () {
  //     var sz = document.createElement("script")
  //     sz.type = "text/javascript"
  //     sz.async = true
  //     sz.src = "//siteimproveanalytics.com/js/siteanalyze_300776.js"
  //     var s = document.getElementsByTagName("script")[0]
  //     s.parentNode.insertBefore(sz, s)
  //   })()
  // },
  filterOrder: ["default", "serviceAreas", "ages", "days", "onlyShow"],
}

export default vars_scc
