import styled from "styled-components"
import tick from "./tick.svg"

export const Form = styled.form`
  text-align: left;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    width: 100%;
  }
`

export const Error = styled.p`
  background: red;
  color: white;
  padding: 4px 10px;
`

export const Fieldset = styled.fieldset`
  border: none;
  margin-bottom: 20px;
`

export const FormHeading = styled.h2`
  margin-bottom: 20px;
`

export const FieldsetHeading = styled.h3`
  margin-bottom: 15px;
`

export const Field = styled.div`
  position: relative;
  margin-bottom: 15px;
`

export const Input = styled.input`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid ${props => props.theme.styles.text};
  display: block;
  width: 100%;
  height: 45px;
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`

export const RadioField = styled(Field)`
  position: relative;
  padding: 4.5px 0px 4.5px 40px;
  &:focus-within label:before {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`

export const RadioInput = styled.input`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 29px;
  border-radius: 100%;
  height: 29px;
  opacity: 0;
  &:checked + label:after {
    position: absolute;
    content: "";
    display: block;
    border-radius: 100%;
    background: ${props => props.theme.styles.text};
    height: 18px;
    width: 18px;
    left: 5px;
    top: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`

export const Label = styled.label`
  margin-bottom: 5px;
  display: inline-block;
`

export const LabelHelpText = styled.span`
  display: block;
  font-size: 0.8rem;
  color: #666;
  margin-top: 3px;
`

export const CheckboxInput = styled.input`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 29px;
  height: 29px;
  opacity: 0;
  &:checked + label:after {
    position: absolute;
    content: "";
    display: block;
    height: 18px;
    width: 18px;
    left: 5px;
    top: 5px;
    background-image: url(${tick});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`

export const CheckboxLabel = styled.label`
  color: ${props => props.theme.styles.text};
  cursor: pointer;
  &:before {
    content: "";
    display: inline-block;
    background: ${props => props.theme.styles.white};
    border: 2px solid ${props => props.theme.styles.text};
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
`

export const RadioLabel = styled(CheckboxLabel)`
  &:before {
    border-radius: 100%;
  }
`

export const Textarea = styled.textarea`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid ${props => props.theme.styles.text};
  display: block;
  width: 100%;
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`

export const Select = styled.select`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid ${props => props.theme.styles.text};
  display: block;
  width: 100%;
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`
