import React, { useState, useContext } from "react"

import styled from "styled-components"
import search from "./search.svg"
import locationIcon from "./location.svg"

import AutocompletePlaceInput from "../AutocompletePlaceInput"
import Spinner from "../Spinner"
import { AlertContextConsumer } from "../../contexts/alertContext"
import Tooltip from "../Tooltip"
import AutocompleteSearchInput from "../AutocompleteSearchInput"
import { useLocation, useNavigate } from "@gatsbyjs/reach-router"
import { normalizeQuerystring } from "../../lib/utils"
import InputIconButton from "../InputIconButton"

import { AppSettingsContext } from "../../contexts/appSettingsContext"

const Form = styled.form`
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }
`

const Field = styled.div`
  margin-bottom: 15px;
  position: relative;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin-bottom: 0px;
    margin-right: 20px;
    flex: 1;
  }
`

const Label = styled.label`
  margin-bottom: 5px;
  display: inline-block;
`

const Button = styled.button`
  background: ${props => props.theme.styles.cta};
  border: none;
  text-align: center;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  height: 45px;
  &:hover {
    background: ${props => props.theme.styles.ctaHover};
  }
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
  &:active {
    background: ${props => props.theme.styles.ctaActive};
  }
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    width: 100px;
  }
`

const SearchBar = ({
  keywords,
  setKeywords,
  coverage,
  setCoverage,
  setLat,
  setLng,
  setPage,
  triggerAlert,
  initialLat = "",
  initialLng = "",
  showSearchField = true,
}) => {
  const [finding, setFinding] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [localKeywords, setLocalKeywords] = useState(keywords)
  const [localCoverage, setLocalCoverage] = useState(coverage)
  const [localLat, setLocalLat] = useState(initialLat)
  const [localLng, setLocalLng] = useState(initialLng)
  const settings = useContext(AppSettingsContext)
  const handleSubmit = e => {
    e && e.preventDefault && e.preventDefault()
    setKeywords(localKeywords)
    setCoverage(localCoverage)
    setLat(localLat)
    setLng(localLng)
    setFinding(false)
    setPage(1)
  }

  const handleAutocompleteSelect = async selectedItem => {
    if (selectedItem?.result_type === "service") {
      let { search } = location
      search = normalizeQuerystring(search)
      navigate(`${settings.basePath || ""}/service/${selectedItem.id}${search}`)
    } else {
      setLocalKeywords(selectedItem?.text)
      setKeywords(selectedItem?.text)
      setFinding(false)
      setPage(1)
    }
  }

  const geolocate = () => {
    setFinding(true)
    navigator.geolocation.getCurrentPosition(
      async position => {
        let { latitude, longitude } = position.coords
        let res = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
        )
        let { address } = await res.json()
        setLocalCoverage(address.postcode)
        setLocalLat(latitude)
        setLocalLng(longitude)
        setFinding(false)
      },
      error => {
        triggerAlert(
          "Couldn't find your current location. Please enter it another way."
        )
        setFinding(false)
      }
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      {showSearchField && (
        <Field>
          <AutocompleteSearchInput
            name="query"
            id="query"
            placeholder="Enter a search..."
            onChange={setLocalKeywords}
            onSelect={handleAutocompleteSelect}
            value={localKeywords}
          />
        </Field>
      )}
      <Field>
        <Label htmlFor="location">Where</Label>
        <AutocompletePlaceInput
          name="location"
          id="location"
          placeholder="Town or postcode"
          value={localCoverage}
          onChange={value => setLocalCoverage(value)}
          setLat={setLocalLat}
          setLng={setLocalLng}
        />
        {navigator.geolocation && finding ? (
          <Spinner />
        ) : (
          <Tooltip label="Use current location">
            <InputIconButton onClick={geolocate} type="button">
              <img src={locationIcon} alt="Use current location" />
            </InputIconButton>
          </Tooltip>
        )}
      </Field>
      <Button type="submit">
        <img src={search} alt="search" />
      </Button>
    </Form>
  )
}

const WrappedInput = props => (
  <AlertContextConsumer>
    {context => <SearchBar triggerAlert={context.triggerAlert} {...props} />}
  </AlertContextConsumer>
)

export default WrappedInput
