{
  "valid": [
    {
      "label": "generic",
      "name": "Generic"
    },
    {
      "label": "scc",
      "name": "Southampton City Council"
    }
  ]
}