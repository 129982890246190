export const orderFilters = (filters, filterOrder) => {
  const defaultIndex = filterOrder.indexOf("default")

  filters.sort((a, b) => {
    let aIndex = filterOrder.indexOf(a.id)
    if (aIndex === -1) {
      aIndex = defaultIndex
    }
    let bIndex = filterOrder.indexOf(b.id)
    if (bIndex === -1) {
      bIndex = defaultIndex
    }
    return aIndex > bIndex ? 1 : aIndex === bIndex ? 0 : -1
  })

  return filters.map(f => f.component)
}
