const events = {
  SUGGESTION_SUBMITTED: "SUGGESTION_SUBMITTED",
}

const dispatch = (type, data) => {
  if (data) {
    window.dispatchEvent(new CustomEvent(type, { detail: data }))
  } else {
    window.dispatchEvent(new Event(type))
  }
}

const subscribe = (type, callback) => {
  window.addEventListener(type, callback)
}

const unsubscribe = (type, callback) => {
  window.removeEventListener(type, callback)
}

export { dispatch, subscribe, unsubscribe, events }
