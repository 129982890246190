import { useState } from "react"
import styled from "styled-components"

import downArrow from "./down-arrow.svg"
import upArrow from "./up-arrow.svg"

export const Fieldset = styled.fieldset`
  border: none;
  margin-bottom: 20px;
`

export const FieldsetInner = styled.div`
  padding-top: 20px;
`

export const FieldsetHeading = styled.h3`
  font-size: 1.1rem;
  font-weight: normal;
`

const Button = styled.button`
  width: 100%;
  padding: 10px 0px;
  text-align: left;
  color: ${props => props.theme.styles.text};
  margin-top: 0px;
  border: none;
  background: none;
  border-bottom: 1px solid ${props => props.theme.styles.text};
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
  &:active {
    color: ${props => props.theme.styles.text};
  }
  &:after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(${downArrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &[aria-expanded="true"]:after {
    background-image: url(${upArrow});
  }
`

const CollapsibleFieldset = ({ heading, children, initiallyOpen = false }) => {
  const [open, setOpen] = useState(initiallyOpen)
  return (
    <Fieldset>
      <Button
        onClick={() => setOpen(!open)}
        type="button"
        aria-expanded={open ? "true" : "false"}
        data-no-autofocus={initiallyOpen ? true : undefined}
      >
        <FieldsetHeading>{heading}</FieldsetHeading>
      </Button>
      {open && <FieldsetInner>{children}</FieldsetInner>}
    </Fieldset>
  )
}

export default CollapsibleFieldset
