// A CUSTOM HOOK TO SYNC URL QUERY WITH STATE
// BASED ON: https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f

import { useState, useEffect } from "react"
import { events, subscribe, unsubscribe } from "../lib/events"
import useQuery from "./useQuery"

const useReminderBanner = serviceId => {
  const [validatedReminderToken, setValidatedReminderToken] = useState()
  const [loading, setLoading] = useState(false)
  const [reminderTokenQS, setReminderTokenQS] = useQuery("reminder_token")
  const [reminderAlreadyCompleted, setReminderAlreadyCompleted] = useState(
    false
  )
  const [showReminderThanks, setShowReminderThanks] = useState(false)

  const completeReminder = async () => {
    if (!validatedReminderToken) {
      return
    }

    try {
      setLoading(true)
      await fetch(
        `${process.env.REACT_APP_API_HOST}/services/${serviceId}/reminders/complete/${validatedReminderToken}`,
        {
          method: "POST",
        }
      )
      setReminderTokenQS(undefined)
      setValidatedReminderToken(undefined)
      setShowReminderThanks(true)
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(
    () =>
      (async () => {
        if (!reminderTokenQS) {
          return
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_HOST}/services/${serviceId}/reminders/validate/${reminderTokenQS}`
          )
          if (response.status === 200) {
            const data = await response.json()
            if (data === "Active") {
              setValidatedReminderToken(reminderTokenQS)
            } else if (data === "Completed") {
              setReminderAlreadyCompleted(true)
              setReminderTokenQS(undefined)
              setValidatedReminderToken(undefined)
            }
          } else {
            setValidatedReminderToken(undefined)
          }
        } catch {
          setValidatedReminderToken(undefined)
        }
      })(),
    [reminderTokenQS]
  )

  useEffect(() => {
    const onSuggestionSubmitted = () => {
      if (!validatedReminderToken) {
        return
      }

      setReminderTokenQS(undefined)
      setValidatedReminderToken(undefined)
      setShowReminderThanks(true)
    }

    subscribe(events.SUGGESTION_SUBMITTED, onSuggestionSubmitted)
    return () => {
      unsubscribe(events.SUGGESTION_SUBMITTED, onSuggestionSubmitted)
    }
  }, [validatedReminderToken])

  return {
    completeReminder,
    reminderAlreadyCompleted,
    showThanksMessage: showReminderThanks,
    showReminderBanner:
      !!validatedReminderToken ||
      showReminderThanks ||
      reminderAlreadyCompleted,
    validatedReminderToken,
    loading,
  }
}

export default useReminderBanner
