import React from "react"
import { Alert } from "@reach/alert"
import { Link } from "@gatsbyjs/reach-router"
import styled from "styled-components"
import { theme } from "./../../themes/theme_generator"

const white = theme.styles.white
const focus = theme.styles.focus
const breakpointM = theme.styles.breakpointM

const StyledAlert = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  padding: 20px;
  background: rgb(33, 33, 33);
  color: ${white};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  animation: popIn 5s ease-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  @media screen and (min-width: ${breakpointM}) {
    max-width: 400px;
    margin: 25px;
    text-align: left;
  }
  @keyframes popIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    2% {
      opacity: 1;
      transform: translateY(0px);
    }
    97% {
      opacity: 1;
      transform: translateY(0px);
    }
    100% {
      opacity: 0;
      transform: translateY(10px);
    }
  }
`

const StyledLink = styled(Link)`
  font-weight: bold;
  color: ${white};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 3px solid ${focus};
    background: ${focus};
  }
`

export default ({ children, link, linkText }) => (
  <Alert as={StyledAlert}>
    {children}
    {link && <StyledLink to={link}>{linkText}</StyledLink>}
  </Alert>
)
