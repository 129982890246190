import React from "react"

import { useForm } from "react-hook-form"

import Button from "../../Button"
import {
  Form,
  Error,
  Fieldset,
  FormHeading,
  Field,
  Label,
  Select,
  Textarea,
} from "../layout"

const SuggestClosure = ({ onSubmit, suggestion }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ defaultValues: { ...suggestion } })

  return (
    <Form onSubmit={handleSubmit(data => onSubmit(data))}>
      <FormHeading>Service closing</FormHeading>
      <Fieldset>
        <Field>
          <Label htmlFor="closureType">
            Is the service closed temporarily or permanently? *
          </Label>

          <Select
            id="closureType"
            {...register("closureType", { required: true })}
            autoFocus
          >
            <option value="">Please select</option>
            <option value="Temporary">Temporary closure</option>
            <option value="Permanent">Permanently closed</option>
          </Select>

          {errors.closureType && <Error>Please select an option</Error>}
        </Field>
        <Field>
          <Label htmlFor="suggestionText">
            Can you tell us more about the closure?
          </Label>
          <Textarea
            id="suggestionText"
            {...register("suggestionText")}
            rows={5}
          ></Textarea>
        </Field>
      </Fieldset>
      <Button type="submit">Next</Button>
    </Form>
  )
}

export default SuggestClosure
