import React from "react"
import ReactDOM from "react-dom"
import { LocationProvider, Router } from "@gatsbyjs/reach-router"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

import App from "./App"
import DetailDialog from "./components/DetailDialog"
import PinboardDialog from "./components/PinboardDialog"
import PrintablePinboard from "./components/PrintablePinboard"
import ThanksPage from "./components/ThanksPage"

import { GoogleContextProvider } from "./contexts/googleContext"
import { AlertContextProvider } from "./contexts/alertContext"
import { DialogContextProvider } from "./contexts/dialogContext"
import { PinboardContextProvider } from "./contexts/pinboardContext"
import { AppSettingsContextProvider } from "./contexts/appSettingsContext"
import { createMemoryOrBrowserHistory } from "./lib/history"
import { addCookiesAcceptedListener } from "./lib/cookies"

import { StyleSheetManager, ThemeProvider } from "styled-components"
import { theme } from "./themes/theme_generator"
import { LocationProvider } from "@gatsbyjs/reach-router"
import SuggestServiceDialog from "./components/SuggestionDialog/SuggestNewServiceDialog"
import SuggestAnEditDialog from "./components/SuggestionDialog/SuggestAnEditDialog"

function init(settings = {}) {
  const selector = settings.selector
  // get our shadow HOST
  const root = document.querySelector(selector || "#directory-spa-root")
  // create a shadow root inside it
  const shadow = root.attachShadow({ mode: "open" })
  // create a slot where we will attach the StyleSheetManager
  const styleSlot = document.createElement("section")
  // append the styleSlot inside the shadow
  shadow.appendChild(styleSlot)
  // create the element where we would render our app
  const renderIn = document.createElement("div")
  renderIn.style.fontSize = "16px" // reset font size to ensure consistent sizing when embedded
  // append the renderIn element inside the styleSlot
  styleSlot.appendChild(renderIn)

  const history = createMemoryOrBrowserHistory(settings.embedded)

  addCookiesAcceptedListener()

  // store the shadowRoot in context
  settings.shadowRoot = shadow

  const routerProps = {}
  if (settings.basePath && !settings.embedded) {
    settings.basePath = settings.basePath.replace(/\/$/, "") // trim trailing /
    routerProps.basepath = settings.basePath
  }

  if (process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        cookieCfg: {
          enabled: false,
        },
      },
    })
    appInsights.loadAppInsights()
  }

  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <StyleSheetManager target={styleSlot}>
          <PinboardContextProvider>
            <GoogleContextProvider>
              <DialogContextProvider>
                <AppSettingsContextProvider value={settings}>
                  <LocationProvider history={history}>
                    <AlertContextProvider>
                      <Router {...routerProps}>
                        <App path="/" default>
                          <DetailDialog path="service/:serviceId" />
                          <SuggestAnEditDialog path="service/:serviceId/suggest-an-edit/*" />
                          <SuggestServiceDialog path="suggest-service/*" />
                          <PinboardDialog path="pinboard" />
                        </App>
                        <PrintablePinboard path="print/" />
                        <ThanksPage path="validation" />
                      </Router>
                    </AlertContextProvider>
                  </LocationProvider>
                </AppSettingsContextProvider>
              </DialogContextProvider>
            </GoogleContextProvider>
          </PinboardContextProvider>
        </StyleSheetManager>
      </ThemeProvider>
    </React.StrictMode>,
    renderIn
  )
}

window.scc = window.scc || {}
window.scc.directory = init

const event = new Event("SCCDirectoryReady")
window.dispatchEvent(event)

export default init
