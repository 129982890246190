import React from "react"
import PerformanceDot from "../PerformanceDot"

const colors = {
  Outstanding: "#336600",
  Good: "#9fc63b",
  "Requires Improvement": "#f90",
  Inadequate: "#bf1000",
  "Insufficient evidence to rate": "#d8d8d8",
  "Inspected but not rated by policy": "#d8d8d8",
  "Inspected but not rated": "#d8d8d8",
  default: "#d8d8d8",
}

export default ({ rating }) => {
  const color = colors[rating] || colors.default
  return <PerformanceDot backgroundColor={color} />
}
