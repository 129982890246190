import React from "react"
import styled from "styled-components"

import DefaultButton from "../Button"

const ReminderBanner = styled.div`
  background: ${props => props.theme.styles.infoAlertBackground};
  padding: 20px;
  font-size: 1rem;
  color: ${props => props.theme.styles.text};
  text-align: center;
  min-height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: 15px 50px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
  }
`

const P = styled.p`
  color: ${props => props.theme.styles.text};
`

const Heading = styled.h3`
  color: ${props => props.theme.styles.text};
  font-size: 1rem;
`

const Actions = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin-top: 0;
    flex-direction: row;
    gap: 20px;
  }
`

const Button = styled(DefaultButton)`
  padding: 10px 20px;
  font-size: 0.9rem;
  &:disabled {
    background: none;
    opacity: 0.5;
  }
`

const LinkButton = styled(Button)`
  border: none;
  text-decoration: underline;
  padding: 10px;
`

export default ({
  suggestEdit,
  showReminderBanner,
  completeReminder,
  showThanksMessage,
  reminderAlreadyCompleted,
  loading = false,
}) => {
  if (!showReminderBanner) {
    return null
  }
  return (
    <ReminderBanner>
      {reminderAlreadyCompleted ? (
        <>
          <Heading>Thank you!</Heading>
          <P>
            You have already responded to this request to update your details.
          </P>
        </>
      ) : showThanksMessage ? (
        <>
          <Heading>Thank you!</Heading>
          <P>We appreciate your help in keeping your information up to date.</P>
        </>
      ) : (
        <>
          <div>
            <Heading>Are your details up to date?</Heading>
          </div>
          <Actions>
            <LinkButton type="button" onClick={suggestEdit} disabled={loading}>
              Suggest an edit
            </LinkButton>
            <Button type="button" onClick={completeReminder} disabled={loading}>
              Everything looks good
            </Button>
          </Actions>
        </>
      )}
    </ReminderBanner>
  )
}
