import { useEffect } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import ReactGA from "react-ga4"
import { useCookiesAccepted } from "../lib/cookies"
import useQuery from "./useQuery"

const useFathom = () => {
  const location = useLocation()
  const cookiesAccepted = useCookiesAccepted()
  const [keywords] = useQuery("keywords", undefined)
  const [coverage] = useQuery("location", undefined)

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.send("pageview", location.pathname + location.search)
    }
  }, [cookiesAccepted, location])

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.event({
        category: "Search",
        action: "Keyword Search",
        label: keywords,
      })
    }
  }, [cookiesAccepted, keywords])

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.event({
        category: "Search",
        action: "Location Search",
        label: coverage,
      })
    }
  }, [cookiesAccepted, coverage])

  useEffect(() => {
    if (!cookiesAccepted) {
      return
    }
    const onFilterChange = ({ detail: { legend, option, checked } }) => {
      if (!checked) {
        return
      }
      ReactGA.event({
        category: "Filter",
        action: legend,
        label: option.name,
      })
    }

    window.addEventListener("FilterChange", onFilterChange)
    return () => window.removeEventListener("FilterChange", onFilterChange)
  }, [cookiesAccepted])
}

export default useFathom
