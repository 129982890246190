import React from "react"

import { P } from "../../Dialog"
import { Form, Fieldset, FormHeading } from "../layout"

const ThankYou = ({ onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit()
  }
  return (
    <Form onSubmit={handleSubmit}>
      <FormHeading>Almost done</FormHeading>
      <Fieldset>
        <P>Please check your email to finish submitting your suggestion.</P>
      </Fieldset>
    </Form>
  )
}

export default ThankYou
