/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef, useContext } from "react"
import { Helmet } from "react-helmet"
import useQuery from "./hooks/useQuery"
import useFathom from "./hooks/useFathom"

// fetch data for the app and filters
import { fetchServiceData, fetchSiteData } from "./lib/api"
import { setAllPaginationValues, normalizeQuerystring } from "./lib/utils"
import daysOptionsData from "./data/_days.json"
import onlyOptionsData from "./data/_only.json"

import Layout, {
  ResultsHeader,
  ResultsList,
  Count as StyledCount,
  NoResults,
  PostResultsSection,
} from "./components/Layout"

import { orderFilters } from "./lib/order-filters"

import Switch from "./components/Switch"
import SearchBar from "./components/SearchBar"
import ServiceCard from "./components/ServiceCard"
import Skeleton from "./components/ServiceCard/Skeleton"
import Filters from "./components/Filters"
import Filter from "./components/Filter"
import AgeFilter from "./components/Filter/AgeFilter"
import ListMap from "./components/ListMap"
import Pagination from "./components/Pagination"
import PinboardLink from "./components/PinboardLink"
import { theme } from "./themes/theme_generator"
import ClearFilters from "./components/ClearFilters"
import Credits from "./components/Credits"
import { Link } from "@gatsbyjs/reach-router"
import { AppSettingsContext } from "./contexts/appSettingsContext"
import A from "./components/A"
import { decode } from "html-entities"

const App = ({ children, location, navigate }) => {
  const scrollTarget = useRef(null)

  const [keywords, setKeywords] = useQuery("keywords", "")

  const [coverage, setCoverage] = useQuery("location", "")
  const [lat, setLat] = useQuery("lat", "")
  const [lng, setLng] = useQuery("lng", "")

  const [taxonomies, setTaxonomies] = useQuery("taxonomy_id", [], {
    array: true,
  })
  const [ages, setAges] = useQuery("ages", [], { array: true })
  const [serviceAreas, setServiceAreas] = useQuery("service_area", [], {
    array: true,
  })
  const [days, setDays] = useQuery("day", [], { array: true })
  const [minAge, setMinAge] = useQuery("minimum_age", false, {
    numerical: true,
  })
  const [maxAge, setMaxAge] = useQuery("maximum_age", false, {
    numerical: true,
  })
  const [only, setOnly] = useQuery("only", [], { array: true })

  const [mapVisible, setMapVisible] = useQuery("map", false, { boolean: true })

  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(true)

  const [page, setPage] = useQuery("page", 1, { numerical: true })
  const [pagination, setPagination] = useState({})

  // filter options
  const [vocabularies, setVocabularies] = useState([])
  const [serviceAreaOptions, setServiceAreaOptions] = useState([])
  const [daysOptions, setDaysOptions] = useState(daysOptionsData)
  const [onlyOptions, setOnlyOptions] = useState(onlyOptionsData)

  // Embedded view variables
  const settings = useContext(AppSettingsContext)
  const embedded = settings?.embedded
  const resultsPerPage = settings?.resultsPerPage
  const fullDirectoryUrl = decode(settings?.fullDirectoryUrl)
  const filteredUrl = decode(settings?.filteredUrl)
  const index = filteredUrl ? filteredUrl.indexOf("?") : null
  const embedQuery = filteredUrl ? filteredUrl.substring(index) : null
  const [embedInitialised, setEmbedInitialised] = useState(false)

  const locationSearch = normalizeQuerystring(location.search)

  useEffect(() => {
    if (!embedded) {
      return
    }
    ;(async () => {
      await navigate(`${embedQuery}`, { replace: true })
      setEmbedInitialised(true)
    })()
  }, [embedded, embedQuery])

  useFathom()

  // only fetch once on site load
  useEffect(() => {
    fetchSiteData()
      .then(([vocabs, taxonomies, serviceAreaOptions]) => {
        vocabs = vocabs
          .filter(v => v.enable_filtering)
          .map(v => {
            v.taxonomies = taxonomies.filter(t => t.vocabulary === v.id)
            return v
          })
        setVocabularies(vocabs)
        setServiceAreaOptions(serviceAreaOptions)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  // on page search update the data
  useEffect(() => {
    // ensure embed URL has initialised before loading data
    if (embedded && !embedInitialised) {
      return
    }
    setLoading(true)
    fetchServiceData(locationSearch, resultsPerPage || theme.itemsPerPage)
      .then(data => {
        setResults(data.content)
        setPagination(
          setAllPaginationValues(
            data.totalElements,
            data.totalPages,
            data.number,
            resultsPerPage || theme.itemsPerPage
          )
        )
        setLoading(false)
      })
      .catch(() => {
        setResults([])
        setLoading(false)
      })
  }, [locationSearch, embedQuery, embedded, embedInitialised])

  const renderFilter = (
    key,
    legend,
    options,
    selection,
    setSelection,
    fullSelection = selection,
    initiallyOpen
  ) =>
    options.length > 0 && (
      <Filter
        key={key}
        legend={legend}
        options={options}
        selection={selection}
        fullSelection={fullSelection}
        setSelection={setSelection}
        setPage={setPage}
        foldable
        initiallyOpen={initiallyOpen}
      />
    )

  const filterAges = (
    <AgeFilter
      key="ages"
      legend="Ages"
      maxAge={maxAge}
      setMaxAge={setMaxAge}
      minAge={minAge}
      setMinAge={setMinAge}
      setPage={setPage}
      foldable
    />
  )

  const getSelectedTaxonomiesForVocab = vocabularyId => {
    return taxonomies.filter(t => t.indexOf(`${vocabularyId}:`) === 0) // filter by taxonomies that start with the vocabulary curie
  }

  const filterToVisibleTaxonomies = taxonomyArray => {
    return taxonomyArray.filter(
      t =>
        t.prerequisite_taxonomies.length === 0 || // either there are no prerequisites to showing this taxonomy or the prerequisite is satified
        t.prerequisite_taxonomies.some(t1 =>
          taxonomies.some(t2 => !!t2 && t2.toLowerCase() === t1?.toLowerCase())
        )
    )
  }

  const filters = vocabularies
    .filter(
      v =>
        getSelectedTaxonomiesForVocab(v.id).length > 0 || // if there's anything selected for this vocabulary
        v.prerequisite_taxonomies.length === 0 || // either there are no prerequisites to showing this vocabulary or the prerequisite is satified
        v.prerequisite_taxonomies.some(t1 =>
          taxonomies.some(t2 => !!t2 && t2.toLowerCase() === t1?.toLowerCase())
        )
    )
    .map((v, index) => ({
      id: v.id,
      component: renderFilter(
        v.id,
        v.name,
        filterToVisibleTaxonomies(v.taxonomies),
        getSelectedTaxonomiesForVocab(v.id),
        setTaxonomies,
        taxonomies,
        index === 0
      ),
      clear: [setTaxonomies],
      clearValue: [[]],
    }))
    .concat([
      {
        id: "ages",
        component: filterAges,
        clear: [setMinAge, setMaxAge],
        clearValue: [false, false],
      },
      {
        id: "serviceAreas",
        component: renderFilter(
          "serviceAreas",
          "Areas served",
          serviceAreaOptions,
          serviceAreas,
          setServiceAreas
        ),
        clear: [setServiceAreas],
        clearValue: [[]],
      },
      {
        id: "onlyShow",
        component: renderFilter(
          "onlyShow",
          "Only show",
          onlyOptions,
          only,
          setOnly
        ),
        clear: [setOnly],
        clearValue: [[]],
      },
      {
        id: "days",
        component: renderFilter("days", "Days", daysOptions, days, setDays),
        clear: [setDays],
        clearValue: [[]],
      },
    ])

  const getAdditionalResultsLabels = () => {
    const additionalResultsLabels = []
    let taxonomyLabels = []
    for (const vocab of vocabularies) {
      const selected = getSelectedTaxonomiesForVocab(vocab.id)
        .map(id => vocab.taxonomies.find(t => t.id === id)?.name)
        .filter(s => !!s)
      if (selected?.length) {
        taxonomyLabels = taxonomyLabels.concat(selected)
      }
    }
    if (taxonomyLabels.length) {
      additionalResultsLabels.push({ label: "for", selections: taxonomyLabels })
    }

    if (minAge) {
      additionalResultsLabels.push({
        label: "min age",
        selections: [minAge],
      })
    }

    if (maxAge) {
      additionalResultsLabels.push({
        label: "max age",
        selections: [maxAge],
      })
    }

    if (serviceAreas.length) {
      additionalResultsLabels.push({
        label: "serving",
        selections: serviceAreas
          .map(id => serviceAreaOptions.find(sao => sao.id === id)?.name)
          .filter(s => !!s),
      })
    }

    if (days.length) {
      additionalResultsLabels.push({
        label: "open",
        selections: days
          .map(id => daysOptions.find(day => day.id === id)?.name)
          .filter(s => !!s),
      })
    }

    if (only.length) {
      additionalResultsLabels.push({
        label: "showing only",
        selections: only
          .map(id => onlyOptions.find(oo => oo.id === id)?.name)
          .filter(s => !!s),
      })
    }

    return additionalResultsLabels
  }

  return (
    <>
      {embedded ? null : (
        <Helmet>
          <title>
            {page > 1 ? `Page ${page} ` : `${theme.tagline} `}| {theme.title} |{" "}
            {theme.organisation}
          </title>
        </Helmet>
      )}
      <Layout
        scrollRef={scrollTarget}
        headerComponents={
          embedded ? null : (
            <SearchBar
              keywords={keywords}
              setKeywords={setKeywords}
              coverage={coverage}
              setCoverage={setCoverage}
              setLat={setLat}
              setLng={setLng}
              setPage={setPage}
              initialLat={lat}
              initialLng={lng}
            />
          )
        }
        sidebarComponents={
          embedded ? null : (
            <Filters>
              {orderFilters(filters, theme.filterOrder)}
              <ClearFilters setPage={setPage} filters={filters} />
            </Filters>
          )
        }
        mainContentComponents={
          <>
            {embedded && embedInitialised && (
              <>
                <h2>Find a service</h2>
                <br />
                <p>
                  Find services near you by entering your location, or{" "}
                  <a href={`${fullDirectoryUrl}${locationSearch}` || "/"}>
                    search the full directory
                  </a>
                </p>
                <br />
                <SearchBar
                  keywords={keywords}
                  setKeywords={setKeywords}
                  coverage={coverage}
                  setCoverage={setCoverage}
                  setLat={setLat}
                  setLng={setLng}
                  setPage={setPage}
                  initialLat={lat}
                  initialLng={lng}
                  showSearchField={false}
                />
                <br />
              </>
            )}
            <MainContent
              loading={loading}
              results={results}
              keywords={keywords}
              coverage={coverage}
              mapVisible={mapVisible}
              setMapVisible={setMapVisible}
              navigate={navigate}
              location={location}
              page={page}
              setPage={setPage}
              scrollTarget={scrollTarget}
              pagination={pagination}
              embedded={embedded}
              additionalResultsLabels={getAdditionalResultsLabels()}
            />
          </>
        }
      />
      {children}
    </>
  )
}

const Count = ({ pagination, keywords, coverage, additionalResultsLabels }) => {
  const { embedded } = useContext(AppSettingsContext)
  return (
    <StyledCount>
      Showing{" "}
      {pagination.currentPage <= pagination.lastPage && (
        <strong>
          {pagination.from} - {pagination.to} out of {pagination.total}{" "}
        </strong>
      )}
      results{" "}
      {embedded &&
        additionalResultsLabels?.length > 0 &&
        additionalResultsLabels.map(
          l =>
            l.selections?.length > 0 && (
              <Fragment key={l.label}>
                {l.label}{" "}
                {l.selections.map((f, i, a) => (
                  <Fragment key={f}>
                    <strong>{f}</strong>
                    {i < a.length - 1 && ", "}
                  </Fragment>
                ))}{" "}
              </Fragment>
            )
        )}
      {keywords && (
        <>
          matching <strong>"{keywords}"</strong>
        </>
      )}{" "}
      {coverage && (
        <>
          near <strong>{coverage}</strong>
        </>
      )}
    </StyledCount>
  )
}

const MainContent = ({
  loading,
  results,
  keywords,
  coverage,
  mapVisible,
  setMapVisible,
  navigate,
  location,
  pagination,
  page,
  setPage,
  scrollTarget,
  additionalResultsLabels,
}) => {
  const { embedded } = useContext(AppSettingsContext)
  const settings = useContext(AppSettingsContext)
  const url = `${settings.basePath || ""}/suggest-service/intro`
  // still loading
  if (loading)
    return (
      <>
        <ResultsHeader>
          <Count
            coverage={coverage}
            keywords={keywords}
            pagination={pagination}
            additionalResultsLabels={additionalResultsLabels}
          />
          <Switch
            id="map-toggle"
            checked={mapVisible}
            onChange={e => setMapVisible(e.target.checked)}
            label="Show map?"
          />
        </ResultsHeader>
        <ResultsList aria-live="polite">
          <Skeleton />
        </ResultsList>
      </>
    )

  // not loading and no results
  if (!results)
    return (
      <NoResults>
        There was a problem fetching results. Please try again later.
      </NoResults>
    )

  // not loading, results exists but is empty array
  if (results.length === 0)
    return <NoResults>No results to show. Try widening your search.</NoResults>

  // not loading, results exist and has length that is not 0
  return (
    <>
      <ResultsHeader>
        <Count
          coverage={coverage}
          keywords={keywords}
          pagination={pagination}
          additionalResultsLabels={additionalResultsLabels}
        />
        <Switch
          id="map-toggle"
          checked={mapVisible}
          onChange={e => setMapVisible(e.target.checked)}
          label="Show map?"
        />
      </ResultsHeader>
      {mapVisible && (
        <ListMap results={results} navigate={navigate} location={location} />
      )}
      <PinboardLink location={location} />
      <ResultsList aria-live="polite">
        {results?.map(s => (
          <ServiceCard key={s.id} {...s} />
        ))}
      </ResultsList>
      <Pagination
        totalPages={pagination.totalPages}
        page={page}
        setPage={setPage}
        scrollTarget={scrollTarget}
      />

      {embedded ? null : (
        <>
          <PostResultsSection>
            <h3>I would like to add my service</h3>
            <p>
              If your service doesn't appear on the list, you can request to
              have your service added to our database by clicking below and
              filling out the form.
            </p>
            <A as={Link} to={url}>
              Suggest a new service
            </A>
          </PostResultsSection>
          <Credits />
        </>
      )}
    </>
  )
}

export default App
