import React from "react"
import styled from "styled-components"

const Dot = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 5px;
`

export default ({ backgroundColor }) => <Dot style={{ backgroundColor }} />
