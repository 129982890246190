import { useEffect, useState } from "react"
import { theme } from "./../themes/theme_generator"

export const getCookie = name => {
  if (typeof document === "undefined") return ""

  let dc = document.cookie
  let prefix = name + "="

  let begin = dc.indexOf(prefix)
  if (begin === -1) return null
  var end = dc.indexOf(";", begin)
  if (end === -1) end = dc.length

  return decodeURI(dc.substring(begin + prefix.length, end))
}

const getCookiesAccepted = () => {
  // if GTM not loaded then we're in test mode with no GA or Civic
  if (!window.dataLayer) {
    return true
  }

  const cookie = getCookie(theme.cookieName)
  return cookie
    ? JSON.parse(cookie)?.optionalCookies?.analytics === "accepted"
    : false
}

export const addCookiesAcceptedListener = (callback, interval = 100) => {
  const interval = setInterval(() => {
    let value = getCookiesAccepted()
    if (value) {
      window.dispatchEvent(new CustomEvent("CookiesAccepted"))
      clearInterval(interval)
    }
  }, interval)
}

export const useCookiesAccepted = () => {
  const [value, setValue] = useState(getCookiesAccepted())

  useEffect(() => {
    const onCookiesAccepted = () => {
      setValue(getCookiesAccepted())
    }
    window.addEventListener("CookiesAccepted", onCookiesAccepted)
    return () =>
      window.removeEventListener("CookiesAccepted", onCookiesAccepted)
  }, [])

  return value
}
