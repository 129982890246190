import React, { useRef, useState } from "react"

import { useForm } from "react-hook-form"

import Button from "../../Button"
import {
  Form,
  Error,
  FormHeading,
  Field,
  RadioField,
  Label,
  Input,
  CheckboxInput,
  CheckboxLabel,
  Textarea,
  LabelHelpText,
} from "../layout"
import CollapsibleFieldset from "../../CollapsibleFieldset"
import fetchTaxonomies from "./fetchTaxonomies"
import { MultiSelectDropdownController } from "../MultiSelectDropdown"
import { formatCurrency, twelveHourTime } from "../../../lib/utils"
import { P as DialogP } from "../../Dialog"
import styled from "styled-components"

const P = styled(DialogP)`
  margin-bottom: 20px;
`

const contactsToString = contacts => {
  return contacts
    .map(c =>
      [c.name, c.title, c.email, ...c.phones.map(p => p.number)]
        .filter(x => !!x)
        .join("\n")
    )
    .join(`\n\n`)
}

const locationsToString = locations => {
  if (!locations[0]?.location?.physical_addresses.length) {
    return ""
  }

  const address = locations[0].location.physical_addresses[0]

  return [
    address.address1,
    address.postal_code,
    address.city,
    address.state_province,
  ]
    .filter(x => !!x)
    .join("\n")
}

const schedulesToString = schedules => {
  return schedules
    .map(
      s =>
        `${s.weekday}s: ${twelveHourTime(s.opens_at)}-${twelveHourTime(
          s.closes_at
        )}`
    )
    .join("\n")
}

const costsToString = costs => {
  return costs
    .map(
      c => `${c.option} - ${formatCurrency(c.amount)} ${c.amount_description}`
    )
    .join("\n")
}

const listToString = items => items.map(t => t.name).join("\n")

const taxonomyListToString = (serviceTaxonomies, taxonomies) => {
  if (!serviceTaxonomies || !taxonomies) {
    return ""
  }
  return listToString(
    taxonomies.filter(i => serviceTaxonomies.some(t => t.id === i.id))
  )
}

const mapServiceToSuggestion = (service, allTaxonomies) => {
  if (!service) {
    return {}
  }
  const ageEligibility = service.eligibilitys.find(e => e.id === "age")
  const serviceTaxonomies = service.service_taxonomys.map(st => st.taxonomy)
  return {
    serviceName: service.name,
    organization: service.organization?.name || "",
    description: service.description,
    location: locationsToString(service.service_at_locations),
    hideAddress: service.hide_address,
    serviceAreas: listToString(service.service_areas),
    contactDetails: contactsToString(service.contacts),
    website: service.url,
    otherLinks: service.links.map(l => `${l.label} - ${l.url}`).join("\n"),
    openingHours: schedulesToString(service.regular_schedules),
    costs: costsToString(service.cost_options),
    minAge: ageEligibility?.minimum_age || "",
    maxAge: ageEligibility?.maximum_age || "",
    localOfferNotes: service.local_offer?.description || "",
    localOfferLink: service.local_offer?.link || "",
    eligibleFor2YoFunding: service.eligible_for2_yo_funding,
    eligibleFor30HEntitlement: service.eligible_for30_h_entitlement,
    eligibleFor34YoFunding: service.eligible_for34_yo_funding,
    eligibleForHafFunding: service.eligible_for_haf_funding,
    requiresReferral: service.needs_referral,
    referralInformation: service.referral_notes,
    ofstedUrn: service.ofsted_urn,
    cqcUrl: service.cqc_url,
    languages: listToString(service.languages),
    schoolTypes: taxonomyListToString(
      serviceTaxonomies,
      allTaxonomies.schoolTypes
    ),
    schoolPickups: listToString(service.school_pickups),
    mainCategories: taxonomyListToString(
      serviceTaxonomies,
      allTaxonomies.mainCategories
    ),
    serviceTypes: taxonomyListToString(
      serviceTaxonomies,
      allTaxonomies.serviceTypes
    ),
    childcareTypes: taxonomyListToString(
      serviceTaxonomies,
      allTaxonomies.childcareTypes
    ),
    specialNeeds: listToString(service.special_needs),
    careAtHomes: taxonomyListToString(
      serviceTaxonomies,
      allTaxonomies.careAtHomes
    ),
  }
}

const SuggestChanges = ({
  onSubmit,
  suggestion,
  service,
  heading = "What changes would you like to make?",
  intro,
  firstFieldsetInitiallyOpen = false,
}) => {
  const [allTaxonomies, setAllTaxonomies] = useState({
    serviceAreas: [],
    languages: [],
    mainCategories: [],
    serviceTypes: [],
    childcareTypes: [],
    schoolTypes: [],
    specialNeeds: [],
    schoolPickups: [],
    careAtHomes: [],
  })
  const initialSuggestion = useRef()
  const getDefaultValues = async () => {
    const taxonomies = await fetchTaxonomies()
    setAllTaxonomies(taxonomies)
    initialSuggestion.current = {
      suggestionType: suggestion.suggestionType,
      suggestionText: "",
      ...mapServiceToSuggestion(service, taxonomies),
    }
    return { ...initialSuggestion.current, ...suggestion }
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    defaultValues: getDefaultValues,
  })

  const diff = (original, updated) => {
    const changed = Object.keys(original).filter(
      key => !updated.hasOwnProperty(key) || original[key] !== updated[key]
    )
    const added = Object.keys(updated).filter(
      key => !original.hasOwnProperty(key)
    )
    return changed.concat(added).reduce((result, key) => {
      result[key] = typeof updated[key] === "undefined" ? "" : updated[key]
      return result
    }, {})
  }

  const transformDataAndSubmit = data => {
    data = diff(initialSuggestion.current, data)
    if (!Object.keys(data).length) {
      return
    }
    onSubmit(data)
  }

  return (
    <Form onSubmit={handleSubmit(transformDataAndSubmit)}>
      <FormHeading>{heading}</FormHeading>
      {intro && <P>{intro}</P>}
      <CollapsibleFieldset
        heading="Basic information"
        initiallyOpen={firstFieldsetInitiallyOpen}
      >
        <Field>
          <Label htmlFor="serviceName">Service name *</Label>
          <Input
            id="serviceName"
            autoFocus
            {...register("serviceName", { required: true })}
          />
          {errors.serviceName && <Error>Please enter service name.</Error>}
        </Field>
        <Field>
          <Label htmlFor="organization">Organisation name *</Label>
          <Input
            id="organization"
            {...register("organization", { required: true })}
          />
          {errors.organization && (
            <Error>Please enter organisation name.</Error>
          )}
        </Field>
        <Field>
          <Label htmlFor="description">Service description *</Label>
          <Textarea
            id="description"
            rows={10}
            {...register("description", { required: true })}
          />
          {errors.description && (
            <Error>Please enter service description.</Error>
          )}
        </Field>
        <MultiSelectDropdownController
          label="Category"
          control={control}
          id="mainCategories"
          allItems={allTaxonomies.mainCategories}
        />
        <MultiSelectDropdownController
          label="Service type"
          control={control}
          id="serviceTypes"
          allItems={allTaxonomies.serviceTypes}
        />
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Address and contact details">
        <Field>
          <Label htmlFor="location">Address</Label>
          <Textarea id="location" {...register("location")} rows={5} />
        </Field>
        <RadioField>
          <CheckboxInput
            type="checkbox"
            id="hideAddress"
            {...register("hideAddress")}
          />
          <CheckboxLabel htmlFor="hideAddress">
            Hide address
            <LabelHelpText>
              Should the address be hidden from public view on the directory?
            </LabelHelpText>
          </CheckboxLabel>
        </RadioField>
        <Field>
          <MultiSelectDropdownController
            label="Areas served"
            helpText="Leave blank for city-wide services"
            control={control}
            id="serviceAreas"
            allItems={allTaxonomies.serviceAreas}
          />
        </Field>
        <Field>
          <Label htmlFor="contactDetails">
            Contact details for this service
            <LabelHelpText>e.g. Phone number and email address</LabelHelpText>
          </Label>
          <Textarea
            id="contactDetails"
            {...register("contactDetails")}
            rows={5}
          />
        </Field>
        <Field>
          <Label htmlFor="website">Website</Label>
          <Input id="website" type="url" {...register("website")} />
        </Field>
        <Field>
          <Label htmlFor="otherLinks">
            Other links
            <LabelHelpText>
              e.g. Links to the service's social media pages
            </LabelHelpText>
          </Label>
          <Textarea id="otherLinks" {...register("otherLinks")} rows={5} />
        </Field>
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Opening hours and costs">
        <Field>
          <Label htmlFor="openingHours">Opening hours</Label>
          <Textarea id="openingHours" {...register("openingHours")} rows={5} />
        </Field>
        <Field>
          <Label htmlFor="costs">Costs</Label>
          <Textarea id="costs" {...register("costs")} rows={5} />
        </Field>
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Who the service is for">
        <Field>
          <Label htmlFor="minAge">Minimum age</Label>
          <Input id="minAge" type="number" {...register("minAge")} />
        </Field>
        <Field>
          <Label htmlFor="maxAge">Maximum age</Label>
          <Input id="maxAge" type="number" {...register("maxAge")} />
        </Field>
        <MultiSelectDropdownController
          label="Languages"
          control={control}
          id="languages"
          allItems={allTaxonomies.languages}
        />
        <Field>
          <Label htmlFor="localOfferNotes">Local offer information</Label>
          <Textarea
            id="localOfferNotes"
            {...register("localOfferNotes")}
            rows={5}
          />
        </Field>
        <Field>
          <Label htmlFor="localOfferLink">Local Offer URL</Label>
          <Input
            id="localOfferLink"
            type="url"
            {...register("localOfferLink")}
          />
        </Field>
        <MultiSelectDropdownController
          label="Special needs supported"
          helpText="What special needs does this service cater for?"
          control={control}
          id="specialNeeds"
          allItems={allTaxonomies.specialNeeds}
        />
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="School/childcare information">
        <Field>
          <Label htmlFor="ofstedUrn">
            Ofsted URN
            <LabelHelpText>
              If this service is regulated by Ofsted you must provide its URN
            </LabelHelpText>
          </Label>
          <Input id="ofstedUrn" {...register("ofstedUrn")} />
        </Field>
        <MultiSelectDropdownController
          label="School type"
          helpText="If this service is a school, select which type of school"
          control={control}
          id="schoolTypes"
          allItems={allTaxonomies.schoolTypes}
        />
        <MultiSelectDropdownController
          label="Childcare type"
          helpText="If this is a childcare service, select which type of childcare"
          control={control}
          id="childcareTypes"
          allItems={allTaxonomies.childcareTypes}
        />
        <MultiSelectDropdownController
          label="School pickups"
          helpText="For services that offer school pickup, select which schools"
          control={control}
          id="schoolPickups"
          allItems={allTaxonomies.schoolPickups}
        />
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Funding eligibility">
        <RadioField>
          <CheckboxInput
            type="checkbox"
            id="eligibleFor2YoFunding"
            {...register("eligibleFor2YoFunding")}
          />
          <CheckboxLabel htmlFor="eligibleFor2YoFunding">
            Eligible for 2 year old funding
          </CheckboxLabel>
        </RadioField>
        <RadioField>
          <CheckboxInput
            type="checkbox"
            id="eligibleFor34YoFunding"
            {...register("eligibleFor34YoFunding")}
          />
          <CheckboxLabel htmlFor="eligibleFor34YoFunding">
            Eligible for three to four year old funding
          </CheckboxLabel>
        </RadioField>
        <RadioField>
          <CheckboxInput
            type="checkbox"
            id="eligibleFor30HEntitlement"
            {...register("eligibleFor30HEntitlement")}
          />
          <CheckboxLabel htmlFor="eligibleFor30HEntitlement">
            Eligible for 30 hour entitlement
          </CheckboxLabel>
        </RadioField>
        <RadioField>
          <CheckboxInput
            type="checkbox"
            id="eligibleForHafFunding"
            {...register("eligibleForHafFunding")}
          />
          <CheckboxLabel htmlFor="eligibleForHafFunding">
            Eligible for HAF funding
          </CheckboxLabel>
        </RadioField>
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Referral information">
        <RadioField>
          <CheckboxInput
            type="checkbox"
            id="requiresReferral"
            {...register("requiresReferral")}
          />
          <CheckboxLabel htmlFor="requiresReferral">
            This service requires a referral
          </CheckboxLabel>
        </RadioField>
        <Field>
          <Label htmlFor="referralInformation">
            More information about referrals
          </Label>
          <Textarea
            id="referralInformation"
            {...register("referralInformation")}
            rows={5}
          />
        </Field>
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Care information">
        <Field>
          <Label htmlFor="cqcUrl">
            CQC website link
            <LabelHelpText>
              If this service is regulated by the Care Quality Commission you
              must provide the URL for the location on the CQC website
            </LabelHelpText>
          </Label>
          <Input
            id="cqcUrl"
            type="url"
            placeholder="e.g. https://www.cqc.org.uk/location/1-127818963"
            {...register("cqcUrl")}
          />
        </Field>
        <MultiSelectDropdownController
          label="Care at home services"
          helpText="Select which care services are offered"
          control={control}
          id="careAtHomes"
          allItems={allTaxonomies.careAtHomes}
        />
      </CollapsibleFieldset>
      <CollapsibleFieldset heading="Anything else?">
        <Field>
          <Label htmlFor="suggestionText">Any other information</Label>
          <Textarea
            id="suggestionText"
            {...register("suggestionText")}
            rows={5}
          />
        </Field>
      </CollapsibleFieldset>
      <Button type="submit">Next</Button>
    </Form>
  )
}

export default SuggestChanges
