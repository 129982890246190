import React from "react"
import useQuery from "../../hooks/useQuery"
import Layout, { PageTitle } from "../Layout"
import styled from "styled-components"

const Container = styled.div`
  max-width: 600px;
`

const ThanksPage = () => {
  const [successQuery] = useQuery("success")
  const success = successQuery === "true"

  return (
    <Layout
      mainContentComponents={
        success ? (
          <Container>
            <PageTitle>Thank you for your suggestion</PageTitle>
            <p>
              We appreciate you helping us keep the information in the directory
              up to date. You will receive a response by email when we have
              processed your suggestion.
            </p>
          </Container>
        ) : (
          <Container>
            <PageTitle>There was an error</PageTitle>
            <p>
              There was an error while validating your suggestion. Please
              resubmit your suggestion, or{" "}
              <a href="https://www.southampton.gov.uk/contact-us/">
                contact us
              </a>{" "}
              if you are still having problems.
            </p>
          </Container>
        )
      }
    ></Layout>
  )
}

export default ThanksPage
