[{
    "id": "MO",
    "name": "Monday"
}, 
{
    "id": "TU",
    "name": "Tuesday"
}, 
{
    "id": "WE",
    "name": "Wednesday"
}, 
{
    "id": "TH",
    "name": "Thursday"
}, 
{
    "id": "FR",
    "name": "Friday"
}, 
{
    "id": "SA",
    "name": "Saturday"
}, 
{
    "id": "SU",
    "name": "Sunday"
}
]
