import React, { useContext, useRef } from "react"
import styled from "styled-components"
import FocusLock, { AutoFocusInside } from "react-focus-lock"
import { RemoveScroll } from "react-remove-scroll"
import {
  DialogContent,
  DialogInner,
  unstable_DialogWrapper as DialogWrapper,
} from "@reach/dialog"
import close from "./close.svg"
import back from "./back.svg"
import { DialogContext } from "../../contexts/dialogContext"

const StyledDialogOverlay = styled("div")`
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
`

const StyledDialog = styled(DialogContent)`
  background: white;
  outline: none;
  position: relative;
  padding: 0px;
  margin: 20px auto;
  width: 92vw;
  max-width: 700px;
  &:hover {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  }
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin: 60px auto;
  }
  animation: splat 0.15s ease-out;
  @keyframes splat {
    from {
      opacity: 0;
      transform: scale(0.99);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const DialogToolbar = styled.div`
  height: ${props => (props.withBackButton ? "40px" : "0")};
`

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.styles.pale};
  }
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`
export const BackButton = styled.button`
  position: absolute;
  left: 5px;
  top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  font-size: 1rem;
  color: #212121;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.styles.pale};
  }
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
  &:disabled {
    opacity: 0.5;
  }
`

export default ({
  handleDismiss,
  dialogTitle,
  children,
  handleBack,
  showToolbar,
  dialogInnerRef,
  enableAutoFocus = true,
}) => {
  const portalContainerRef = useContext(DialogContext)
  return (
    <DialogWrapper containerRef={portalContainerRef}>
      <DialogInner
        onDismiss={handleDismiss}
        aria-label={dialogTitle}
        as={StyledDialogOverlay}
        dangerouslyBypassScrollLock={true}
        dangerouslyBypassFocusLock={true}
        ref={dialogInnerRef}
      >
        <FocusLock
          autoFocus={enableAutoFocus}
          returnFocus={{ preventScroll: !enableAutoFocus }}
          crossFrame
        >
          <RemoveScroll allowPinchZoom enabled={true} noIsolation={true}>
            <DialogContent as={StyledDialog}>
              <DialogToolbar withBackButton={showToolbar}>
                {handleBack && (
                  <BackButton onClick={handleBack}>
                    <Icon src={back} alt="" aria-hidden="true" />
                    Back
                  </BackButton>
                )}
                {handleDismiss && (
                  <CloseButton onClick={handleDismiss}>
                    <Icon src={close} alt="Close dialog" />
                  </CloseButton>
                )}
              </DialogToolbar>

              <AutoFocusInside>{children}</AutoFocusInside>
            </DialogContent>
          </RemoveScroll>
        </FocusLock>
      </DialogInner>
    </DialogWrapper>
  )
}

export const Header = styled.header`
  padding: 25px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: 45px;
  }
`

export const Body = styled.div`
  padding: 25px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: 45px;
  }
  &:nth-of-type(even) {
    background: ${props => props.theme.styles.pale};
  }
`

export const Title = styled.h1`
  color: ${props => props.theme.styles.text};
  margin-bottom: 0px;
  font-size: 1.5rem;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    font-size: 2rem;
  }
`

export const Subtitle = styled.h2`
  color: ${props => props.theme.styles.text};
  margin-bottom: 0.75rem;
  font-size: 1.2rem;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    font-size: 1.75rem;
  }
`

export const P = styled.p`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const Ul = styled.ul`
  margin-bottom: 15px;
  padding-left: 16px;
`

export const Li = styled.li`
  margin-bottom: 10px;
  line-height: 1.5;
`
