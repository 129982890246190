import React from "react"

import { useForm } from "react-hook-form"

import Button from "../../Button"
import {
  Form,
  Error,
  Fieldset,
  FormHeading,
  Field,
  Textarea,
  Label,
} from "../layout"

const SuggestSomethingElse = ({ onSubmit, suggestion }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ defaultValues: { ...suggestion } })

  return (
    <Form onSubmit={handleSubmit(data => onSubmit(data))}>
      <FormHeading>Your suggestion</FormHeading>
      <Fieldset>
        <Field>
          <Label htmlFor="suggestionText">Please enter your suggestion</Label>
          <Textarea
            id="suggestionText"
            {...register("suggestionText", { required: true })}
            rows={5}
            autoFocus
          ></Textarea>

          {errors.suggestionText && <Error>Please enter your suggestion</Error>}
        </Field>
      </Fieldset>
      <Button type="submit">Next</Button>
    </Form>
  )
}

export default SuggestSomethingElse
