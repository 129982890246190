import React, { useState, useEffect } from "react"
import styled from "styled-components"

import {
  daysSince,
  buildGoodToKnow,
  truncate,
  twelveHourTime,
} from "../../lib/utils"
import { Helmet } from "react-helmet"
import "@reach/dialog/styles.css"

import A from "../A"
import PinboardButton from "../PinboardButton"
import Description from "../Description"
import LocalOffer from "../LocalOffer"
import Loader from "../Loader"
import { ButtonLink } from "../Button"
import Dialog, { Body as InheritedBody, Header, Title } from "../Dialog"
import SingleLocation from "./SingleLocation"
import LocationAccordion from "./LocationAccordion"
import { TickList, TickListItem } from "../TickList"
import { theme } from "./../../themes/theme_generator"
import { formatCurrency } from "./../../lib/utils"

import OfstedPerformanceDot from "../OfstedPerformanceDot"
import CqcPerformanceDot from "../CqcPerformanceDot"
import useReminderBanner from "../../hooks/useReminderBanner"
import ReminderBanner from "../ReminderBanner"

const Body = styled(InheritedBody)`
  a {
    color: #002e6f;
  }
  &:first-of-type {
    padding-top: 0px;
  }
  &:empty {
    padding: 1px 0;
  }
`

const Banner = styled.p`
  background: ${props => props.theme.styles.pale};
  padding: 10px 50px;
  font-size: 0.95rem;
  color: ${props => props.theme.styles.grey};
  text-align: center;
`

const InfoBanner = styled(Banner)`
  background: ${props => props.theme.styles.infoAlertBackground};
  color: ${props => props.theme.styles.infoAlertText};
`

const WarningBanner = styled(Banner)`
  background: ${props => props.theme.styles.focus};
  color: ${props => props.theme.styles.warningAlertBackground};
`

const Caption = styled.p`
  color: ${props => props.theme.styles.grey};
  margin-bottom: 10px;
  font-size: 1.1rem;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    font-size: 1.2rem;
  }
`

const Actions = styled.div`
  margin-bottom: 30px;
  a:first-of-type {
    margin-bottom: 25px;
  }
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    a:first-of-type {
      margin-bottom: 0px;
      margin-right: 30px;
    }
  }
`

const TwoColumnTickList = styled(TickList)`
  margin-top: 25px;
  list-style: none;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 35px;
  }
`

const Crosshead = styled.h2`
  margin-bottom: 15px;
  color: ${props => props.theme.styles.text};
`

const Columns = styled.div`
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0px;
  }
  div {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  @supports (display: grid) {
    @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 35px;
      row-gap: 25px;
      div {
        margin-bottom: 0px;
      }
    }
  }
`

const ContactName = styled.h3`
  line-height: 1.5;
  color: ${props => props.theme.styles.text};
`

const ContactRole = styled.p`
  margin-bottom: 5px;
  font-style: italic;
`

const Table = styled.table`
  width: 100%;
  color: ${props => props.theme.styles.text};
  td {
    width: 50%;
  }
  tr:not(:last-child) td {
    padding-bottom: 10px;
  }
`

const Footer = styled(Body)`
  text-align: center;
  padding: 25px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: 45px;
  }
  p {
    margin-bottom: 10px;
    &::last-of-type {
      margin-bottom: 0px;
    }
  }
`

const SuggestEditButton = styled.button`
  display: inline-block;
  text-decoration: none;
  color: ${props => props.theme.styles.link};
  padding: 7px 25px;
  text-align: center;
  border: 3px solid ${props => props.theme.styles.link};
  font-size: 1rem;
  background: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: 200px;
  margin-bottom: 20px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px ${props => props.theme.styles.focus};
  }
  &:hover {
    color: ${props => props.theme.styles.linkHover};
    border-color: ${props => props.theme.styles.linkHover};
  }
  &:active {
    color: ${props => props.theme.styles.linkActive};
    border-color: ${props => props.theme.styles.linkActive};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`

const Cell = styled.div``

const DetailDialog = ({ serviceId, location, navigate }) => {
  const [service, setService] = useState(false)
  const handleDismiss = () => {
    navigate(`../../${location.search}`)
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/services/${serviceId}`)
      .then(res => res.json())
      .then(data => setService(data))
  }, [serviceId])

  const reminderProps = useReminderBanner(serviceId)

  // handle 404
  if (service.error) navigate(`../../`)

  const suggestEdit = () => {
    navigate(`suggest-an-edit${location.search}`)
  }

  if (service.name && service.service_taxonomys) {
    let goodToKnow = buildGoodToKnow(service)
    let categories = service.service_taxonomys.map(st => st.taxonomy)
    return (
      <Dialog handleDismiss={handleDismiss} dialogTitle={service.name}>
        <Helmet>
          <title>
            {service.name} | {theme.title} | {theme.organisation}
          </title>
          <meta
            property="twitter:title"
            content={`${service.name} | ${theme.title} | ${theme.organisation}`}
          />
          <meta
            property="og:title"
            content={`${service.name} | ${theme.title} | ${theme.organisation}`}
          />

          {service.description && (
            <meta
              name="description"
              content={truncate(service.description, 22).replace(
                /(\r\n|\n|\r)/gm,
                " "
              )}
            />
          )}
          {service.description && (
            <meta
              property="twitter:description"
              content={truncate(service.description, 22).replace(
                /(\r\n|\n|\r)/gm,
                " "
              )}
            />
          )}
          {service.description && (
            <meta
              property="og:description"
              content={truncate(service.description, 22).replace(
                /(\r\n|\n|\r)/gm,
                " "
              )}
            />
          )}
        </Helmet>

        {reminderProps.showReminderBanner ? (
          <ReminderBanner {...reminderProps} suggestEdit={suggestEdit} />
        ) : service.alert?.text?.length ? (
          <InfoBanner>{service.alert.text}</InfoBanner>
        ) : service.status === "temporarily closed" ? (
          <WarningBanner>
            This service is <strong>temporarily closed</strong>.
          </WarningBanner>
        ) : (
          daysSince(service.assured_date) > 730 && (
            <Banner>Last updated more than two years ago</Banner>
          )
        )}
        <main>
          <Header>
            {service.organization?.name && (
              <Caption>{service.organization.name}</Caption>
            )}
            <Title>{service.name}</Title>
          </Header>
          {service.service_at_locations.length === 1 && (
            <SingleLocation {...service.service_at_locations[0].location} />
          )}
          <Body>
            <Actions>
              {service.url ? (
                <ButtonLink href={service.url}>Visit website</ButtonLink>
              ) : (
                service.contacts.length === 1 &&
                service.contacts[0].email && (
                  <ButtonLink href={`mailto:${service.contacts[0].email}`}>
                    Send email
                  </ButtonLink>
                )
              )}
              <PinboardButton service={service} location={location} />
            </Actions>
            {service.description && (
              <Description description={service.description} />
            )}
            {service.service_at_locations.length > 1 && (
              <LocationAccordion
                locations={service.service_at_locations.map(
                  sal => sal.location
                )}
              />
            )}
          </Body>
          {goodToKnow.length > 0 && (
            <Body>
              <Crosshead>Good to know</Crosshead>
              <TwoColumnTickList>
                {goodToKnow.map(point => (
                  <TickListItem key={point}>
                    {point}
                    <br />
                    {point === "Needs a referral" && service.referral_url && (
                      <A href={service.referral_url}>Details</A>
                    )}
                  </TickListItem>
                ))}
              </TwoColumnTickList>
            </Body>
          )}
          {service.contacts.length > 0 && (
            <Body>
              <Crosshead>Who to contact</Crosshead>
              <Columns>
                {service.contacts.map(contact => (
                  <div key={contact.id}>
                    <ContactName>{contact.name || service.name}</ContactName>
                    {contact.title && (
                      <ContactRole>{contact.title}</ContactRole>
                    )}
                    {contact.phones &&
                      contact.phones.map(phone => (
                        <p key={phone.number}>
                          <A href={`tel:${phone.number}`}>{phone.number}</A>
                        </p>
                      ))}
                    {contact.email && (
                      <p>
                        <A href={`mailto:${contact.email}`}>{contact.email}</A>
                      </p>
                    )}
                  </div>
                ))}
              </Columns>
            </Body>
          )}
          {service.ofsted_url && service.ofsted_latest_inspection && (
            <Body>
              <Crosshead>Ofsted</Crosshead>
              <Grid>
                <Cell>Ofsted rating</Cell>
                <Cell>
                  <a target="_blank" href={service.ofsted_url}>
                    <OfstedPerformanceDot
                      rating={
                        service.ofsted_latest_inspection.overall_judgement
                      }
                    />
                    {service.ofsted_latest_inspection.overall_judgement}
                  </a>
                </Cell>
                {service.ofsted_latest_inspection.date && (
                  <>
                    <Cell>Inspection date</Cell>
                    <Cell>
                      {new Intl.DateTimeFormat("en-GB").format(
                        new Date(service.ofsted_latest_inspection.date)
                      )}
                    </Cell>
                  </>
                )}
              </Grid>
            </Body>
          )}
          {service.cqc_url && service.cqc_current_rating && (
            <Body>
              <Crosshead>CQC information</Crosshead>
              <Grid>
                <Cell>Current rating</Cell>
                <Cell>
                  <a target="_blank" href={service.cqc_url}>
                    <CqcPerformanceDot rating={service.cqc_current_rating} />
                    {service.cqc_current_rating}
                  </a>
                </Cell>
                {service.cqc_last_inspection && (
                  <>
                    <Cell>Last inspection</Cell>
                    <Cell>
                      {new Intl.DateTimeFormat("en-GB").format(
                        new Date(service.cqc_last_inspection)
                      )}
                    </Cell>
                  </>
                )}
              </Grid>
            </Body>
          )}
          {(service.local_offer || service.special_needs?.length > 0) && (
            <Body>
              <Crosshead>SEND support (Local Offer)</Crosshead>
              <LocalOffer
                {...service.local_offer}
                sendNeeds={service.special_needs}
              />
            </Body>
          )}
          {service.referral_notes && (
            <Body>
              <Crosshead>Referral information</Crosshead>
              <Description description={service.referral_notes} />
            </Body>
          )}
          {service.extra_section_heading && (
            <Body>
              <Crosshead>{service.extra_section_heading}</Crosshead>
              <Description description={service.extra_section_content} />
            </Body>
          )}
          <Body>
            {service.service_at_locations?.length === 1 &&
              service.service_at_locations[0].location?.accessibilities
                ?.length > 0 && (
                <Columns>
                  <Crosshead>Access needs</Crosshead>
                  <TickList>
                    {service.service_at_locations[0].location.accessibilities.map(
                      point => (
                        <TickListItem key={point.name}>
                          {point.name}
                        </TickListItem>
                      )
                    )}
                  </TickList>
                </Columns>
              )}
            {(service.regular_schedules.length > 0 ||
              service.schedule_description) && (
              <Columns>
                <Crosshead>Hours</Crosshead>
                <Table>
                  <tbody>
                    {service.regular_schedules.map((sched, i) => (
                        <tr key={i}>
                          <td>
                            <strong>{sched.weekday}s</strong>
                          </td>
                          {sched.opens_at && sched.closes_at && (
                            <td>
                              {twelveHourTime(sched.opens_at)} to{" "}
                              {twelveHourTime(sched.closes_at)}
                            </td>
                          )}
                        </tr>
                      ))}
                    {service.schedule_description && (
                      <tr>
                        <td colSpan={2}>
                          <p>{service.schedule_description}</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Columns>
            )}
            {service.service_areas.length > 0 && (
              <Columns>
                <Crosshead>Areas served</Crosshead>
                <TickList>
                  {service.service_areas.map((sar, i) => (
                    <TickListItem key={i}>{sar.name}</TickListItem>
                  ))}
                </TickList>
              </Columns>
            )}
            {service.school_pickups.length > 0 && (
              <Columns>
                <Crosshead>School pick up from</Crosshead>
                <TickList>
                  {service.school_pickups.map((sch, i) => (
                    <TickListItem key={i}>{sch.name}</TickListItem>
                  ))}
                </TickList>
              </Columns>
            )}
            {(service.cost_options.length > 0 || service.cost_notes) && (
              <Columns>
                <Crosshead>Fees</Crosshead>
                <Table>
                  <tbody>
                    {service.cost_options.map((fee, i) => (
                      <tr key={i}>
                        {service.cost_options.some(c => c.option) && (
                          <td>
                            <strong>{fee.option}</strong>
                          </td>
                        )}
                        {typeof fee.amount === "number" && (
                          <td>
                            {fee.amount === 0
                              ? "Free"
                              : `${formatCurrency(fee.amount)}`}{" "}
                            {fee.amount_description}
                          </td>
                        )}
                      </tr>
                    ))}
                    {service.cost_notes && (
                      <tr>
                        <td colSpan={2}>
                          <p>{service.cost_notes}</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Columns>
            )}
            {(service.eligible_for2_yo_funding ||
              service.eligible_for30_h_funding ||
              service.eligible_for34_yo_funding ||
              service.eligible_for_haf_funding) && (
              <Columns>
                <Crosshead>Funding eligibility</Crosshead>
                <TickList>
                  {service.eligible_for2_yo_funding && (
                    <TickListItem key="2yo-fund">
                      Eligible for 2 year old funding
                    </TickListItem>
                  )}
                  {service.eligible_for30_h_entitlement && (
                    <TickListItem key="30h-fund">
                      Eligible for 30 hour entitlement
                    </TickListItem>
                  )}
                  {service.eligible_for34_yo_funding && (
                    <TickListItem key="34yo-fund">
                      Eligible for 3-4 year old funding
                    </TickListItem>
                  )}
                  {service.eligible_for_haf_funding && (
                    <TickListItem key="haf-fund">
                      Eligible for HAF funding
                    </TickListItem>
                  )}
                </TickList>
              </Columns>
            )}
            {service.languages.length > 0 && (
              <Columns>
                <Crosshead>Languages</Crosshead>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        {service.languages.map((lang, i) => (
                          <span key={i}>
                            {lang.name}
                            {service.languages.length - 1 === i ? "" : ", "}
                          </span>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Columns>
            )}
            {service.links.length > 0 && (
              <Columns>
                <Crosshead>Links</Crosshead>
                <div>
                  {service.links.map(link => (
                    <p key={link.url}>
                      <A href={link.url}>{link.label}</A>
                    </p>
                  ))}
                </div>
              </Columns>
            )}
            {!theme.serviceCard.hideCategories && categories.length > 0 && (
              <Columns>
                <Crosshead>Categories</Crosshead>
                <p>{categories.map(taxon => taxon.name).join(", ")}</p>
              </Columns>
            )}
          </Body>
          {reminderProps.showReminderBanner ? (
            <ReminderBanner {...reminderProps} suggestEdit={suggestEdit} />
          ) : null}
          <Footer>
            {!reminderProps.showReminderBanner && (
              <>
                <SuggestEditButton onClick={suggestEdit}>
                  Suggest an edit
                </SuggestEditButton>
                <p>
                  If anything here is out of date or missing, please suggest an
                  edit.
                </p>
              </>
            )}

            <p>
              We regularly check and update these community services, but can't
              guarantee that they will always be accurate.
            </p>
            <p>
              You may need a referral for some activities and groups. Contact
              the organiser if unsure.
            </p>
            <p>
              We offer an impartial service and we cannot recommend or endorse
              any providers listed.
            </p>
          </Footer>
        </main>
      </Dialog>
    )
  }
  return <Loader />
}

export default DetailDialog
