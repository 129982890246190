// A CUSTOM HOOK TO SYNC URL QUERY WITH STATE
// BASED ON: https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f

import { useState, useCallback, useEffect } from "react"
import queryString from "query-string"
import { useNavigate, useLocation } from "@gatsbyjs/reach-router"
import { getHistory } from "../lib/history"

// get raw location to avoid lifecycle issue when transitioning multiple times in quick succession
const getLocationImmediate = () => {
  return getHistory().location
}

const useQuery = (key, initialValue, options = {}) => {
  let { numerical, array, boolean } = options

  const location = useLocation()
  const navigate = useNavigate()

  const applyQueryString = newString => {
    navigate(newString, { replace: true })
  }

  const setQueryStringValue = (key, value, query) => {
    const parsedValues = queryString.parse(query)
    let newObject = {
      ...parsedValues,
      [key]: value,
    }
    if (!value) delete newObject[key]
    const newString = queryString.stringify(newObject)
    applyQueryString(`?${newString}`)
  }

  const getQueryStringValue = (key, query) => {
    const value = queryString.parse(query)[key]
    if (numerical) return parseInt(value)
    if (boolean) return !!value
    if (array) return value ? [].concat(value) : []
    return value
  }

  const [value, setValue] = useState(
    getQueryStringValue(key, location.search) || initialValue
  )

  const onSetValue = newValue => {
    setValue(newValue)
    setQueryStringValue(key, newValue, getLocationImmediate().search)
  }

  useEffect(() => {
    var newVal = getQueryStringValue(key, location.search)
    if (numerical && isNaN(newVal)) {
      return
    }
    setValue(newVal)
  }, [location.search])

  return [value, onSetValue]
}

export default useQuery
