import { Router, useMatch } from "@gatsbyjs/reach-router"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { AppSettingsContext } from "../../../contexts/appSettingsContext"
import { postServiceData, updateServiceData } from "../../../lib/api"
import { dispatch, events } from "../../../lib/events"
import Dialog, {
  Body as DialogBody,
  Header as DialogHeader,
  Title as DialogTitle,
} from "../../Dialog"
import EnterYourDetails from "../EnterYourDetails"
import Intro from "../SuggestNewServiceIntro"
import SuggestChanges from "../SuggestChanges"
import ThankYou from "../ThankYou"

const Body = styled(DialogBody)`
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding-top: 30px;
  }
`

const Header = styled(DialogHeader)`
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding-top: 15px;
  }
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
`

const Title = styled(DialogTitle)`
  font-size: 1.2rem;
  margin-top: 0;
`

const SuggestNewServiceDialog = ({ location, navigate }) => {
  const [suggestion, setSuggestion] = useState({})
  const settings = useContext(AppSettingsContext)

  const baseDialogRoute = `${settings.basePath || ""}/suggest-service`

  const handleDismiss = () =>
    navigate(`${settings.basePath || "/"}${location.search}`)

  const routes = [
    {
      Component: Intro,
      path: "intro",
      default: true,
      nextRoutePath: "service-details",
    },
    {
      Component: SuggestChanges,
      path: "service-details",
      heading: "Add service information",
      intro:
        "Please fill in all that is relevant to your service. Fields marked * are required.",
      firstFieldsetInitiallyOpen: true,
      previousRoutePath: "intro",
      nextRoutePath: "your-details",
    },
    {
      Component: EnterYourDetails,
      path: "your-details",
      previousRoutePath: "service-details",
      nextRoutePath: "thank-you",
    },
    {
      Component: ThankYou,
      path: "thank-you",
    },
  ]

  const currentRoute = routes.filter(r =>
    useMatch(`${baseDialogRoute}/${r.path}`)
  )[0]

  const dialogInnerRef = useRef(null)
  useEffect(() => {
    dialogInnerRef.current?.scrollTo(0, 0)
  }, [currentRoute])

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const save = async data => {
    setLoading(true)
    setError(false)
    try {
      const res = await postServiceData(data)
      setLoading(false)
      if (res.status !== 200) {
        const errors = await res.json()
        const err = Object.values(errors).flat().join(" ")
        setError(err)
        throw err
      }
    } catch (err) {
      console.log("error ", err)
      setError(
        "There was an error submitting your details. Please review your answers and try again."
      )
      setLoading(false)
      throw err
    }
  }

  const getSubmitHandler = route => async data => {
    const s = { ...suggestion, ...data }
    setSuggestion(s)

    if (route.nextRoutePath === "thank-you") {
      try {
        await save(data)
      } catch {
        return // don't navigate
      }
    }

    navigate(route.nextRoutePath)
  }

  return (
    <Dialog
      handleDismiss={handleDismiss}
      handleBack={
        currentRoute &&
        currentRoute.previousRoutePath &&
        (() => navigate(currentRoute.previousRoutePath))
      }
      showToolbar={true}
      dialogTitle="Suggest a new service"
      dialogInnerRef={dialogInnerRef}
      enableAutoFocus={false}
    >
      <main>
        <Header>
          <Title>Suggest a new service</Title>
        </Header>
        <Body>
          <Router primary={false}>
            {routes.map(r => (
              <r.Component
                key={r.path}
                {...r}
                onSubmit={getSubmitHandler(r)}
                suggestion={suggestion}
                error={error}
                loading={loading}
              />
            ))}
          </Router>
        </Body>
      </main>
    </Dialog>
  )
}

export default SuggestNewServiceDialog
