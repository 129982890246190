import { Router, useMatch } from "@gatsbyjs/reach-router"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { AppSettingsContext } from "../../../contexts/appSettingsContext"
import useReminderBanner from "../../../hooks/useReminderBanner"
import { updateServiceData } from "../../../lib/api"
import { dispatch, events } from "../../../lib/events"
import Dialog, {
  Body as DialogBody,
  Header as DialogHeader,
  Subtitle as DialogSubtitle,
  Title as DialogTitle,
} from "../../Dialog"
import ChooseSuggestionType from "../ChooseSuggestionType"
import EnterYourDetails from "../EnterYourDetails"
import SuggestChanges from "../SuggestChanges"
import SuggestClosure from "../SuggestClosure"
import SuggestSomethingElse from "../SuggestSomethingElse"
import ThankYou from "../ThankYou"

const Body = styled(DialogBody)`
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding-top: 30px;
  }
`

const Header = styled(DialogHeader)`
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding-top: 15px;
  }
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
`

const Title = styled(DialogTitle)`
  font-size: 1.2rem;
  margin-top: 0;
`

const Subtitle = styled(DialogSubtitle)`
  color: ${props => props.theme.styles.grey};
  margin-top: 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: normal;
`

const SuggestAnEditDialog = ({ location, serviceId, navigate }) => {
  const [suggestion, setSuggestion] = useState({})
  const settings = useContext(AppSettingsContext)
  const [service, setService] = useState(false)
  const reminderProps = useReminderBanner(serviceId)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/services/${serviceId}`)
      .then(res => res.json())
      .then(data => setService(data))
  }, [serviceId])

  const serviceRoute = `${settings.basePath || ""}/service/${serviceId}`
  const baseDialogRoute = `${serviceRoute}/suggest-an-edit`

  const handleDismiss = () =>
    navigate(`${settings.basePath || "/"}${location.search}`)

  const getRouteForSuggestionType = type => {
    switch (type) {
      case "Edit":
        return `${baseDialogRoute}/suggest-changes`
      case "Closed":
        return `${baseDialogRoute}/suggest-closure`
      case "Other":
        return `${baseDialogRoute}/suggest-something-else`
      default:
        return `${baseDialogRoute}`
    }
  }

  const routes = [
    {
      Component: ChooseSuggestionType,
      path: "",
      default: true,
      previousRoutePath: `${serviceRoute}${location.search}`,
      nextRoutePath: data => getRouteForSuggestionType(data.suggestionType),
    },
    {
      Component: SuggestChanges,
      path: "suggest-changes",
      previousRoutePath: baseDialogRoute,
      nextRoutePath: "enter-your-details",
    },
    {
      Component: SuggestClosure,
      path: "suggest-closure",
      previousRoutePath: baseDialogRoute,
      nextRoutePath: "enter-your-details",
    },
    {
      Component: SuggestSomethingElse,
      path: "suggest-something-else",
      previousRoutePath: baseDialogRoute,
      nextRoutePath: "enter-your-details",
    },
    {
      Component: EnterYourDetails,
      path: "enter-your-details",
      previousRoutePath: getRouteForSuggestionType(suggestion.suggestionType),
      nextRoutePath: "thank-you",
    },
    {
      Component: ThankYou,
      path: "thank-you",
    },
  ]

  const currentRoute = routes.filter(r =>
    useMatch(`${baseDialogRoute}/${r.path}`)
  )[0]

  useEffect(() => {
    const index = routes.indexOf(currentRoute)
    // if page is reloaded and we lose state, then take user back to the start
    if (index > 0 && !suggestion.suggestionType) {
      navigate(baseDialogRoute)
    }
  }, [currentRoute, suggestion.suggestionType])

  const dialogInnerRef = useRef(null)
  useEffect(() => {
    dialogInnerRef.current?.scrollTo(0, 0)
  }, [currentRoute])

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const save = async data => {
    setLoading(true)
    setError(false)
    const body = {
      ...data,
      reminderToken: reminderProps.validatedReminderToken,
    }
    try {
      await updateServiceData(service.id, body).then(res => {
        if (res.status === 200) {
          dispatch(events.SUGGESTION_SUBMITTED, body)
        } else {
          setError(
            "There was an error submitting your details. Please review your answers and try again."
          )
          throw "Error submitting suggestion"
        }
        setLoading(false)
      })
    } catch (err) {
      console.log("error ", err)
      setError(
        "There was an error submitting your details. Please review your answers and try again."
      )
      setLoading(false)
      throw err
    }
  }

  const getSubmitHandler = route => async data => {
    const s = { ...suggestion, ...data }
    setSuggestion(s)

    const nextRoutePath =
      typeof route.nextRoutePath === "function"
        ? route.nextRoutePath(data)
        : route.nextRoutePath

    if (nextRoutePath === "thank-you") {
      try {
        await save(data)
      } catch {
        return // don't navigate
      }
    }

    navigate(`${nextRoutePath}${location.search}`)
  }

  return (
    <Dialog
      handleDismiss={handleDismiss}
      handleBack={
        currentRoute &&
        currentRoute.previousRoutePath &&
        (() => navigate(`${currentRoute.previousRoutePath}${location.search}`))
      }
      showToolbar={true}
      dialogTitle="Suggest an edit"
      dialogInnerRef={dialogInnerRef}
      enableAutoFocus={false}
    >
      <main>
        <Header>
          <Title>Suggest an edit</Title>
          <Subtitle>{service?.name || <>&nbsp;</>}</Subtitle>
        </Header>
        <Body>
          <Router primary={false}>
            {routes.map(r => (
              <r.Component
                key={r.path}
                path={r.path}
                default={r.default}
                onSubmit={getSubmitHandler(r)}
                suggestion={suggestion}
                service={service}
                error={error}
                loading={loading}
              />
            ))}
          </Router>
        </Body>
      </main>
    </Dialog>
  )
}

export default SuggestAnEditDialog
