import { fetchData } from "../../../lib/api"

const filterByVocabularyType = (vocabularies, taxonomies, type) => {
  const vocab = vocabularies.find(v => v.type === type)
  return taxonomies.filter(t => t.vocabulary === vocab.id)
}
const fetchTaxonomies = async () => {
  const responses = await Promise.allSettled([
    fetchData("vocabularies?detail=true"),
    fetchData("taxonomies"),
    fetchData("service_areas"),
    fetchData("languages"),
  ])
  const data = responses.map(results => {
    if (results.status === "fulfilled") {
      return results.value
    } else {
      return []
    }
  })
  const vocabularies = data[0]
  const taxonomies = data[1]
  const serviceAreas = data[2]
  const languages = data[3]
  const mainCategories = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "MainCategory"
  )
  const serviceTypes = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "EsdServiceType"
  )
  const childcareTypes = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "ChildcareType"
  )
  const schoolTypes = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "SchoolType"
  )
  const specialNeeds = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "SpecialNeeds"
  )
  const schoolPickups = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "SchoolPickup"
  )
  const careAtHomes = filterByVocabularyType(
    vocabularies,
    taxonomies,
    "CareAtHome"
  )
  return {
    serviceAreas,
    languages,
    mainCategories,
    serviceTypes,
    childcareTypes,
    schoolTypes,
    specialNeeds,
    schoolPickups,
    careAtHomes,
  }
}

export default fetchTaxonomies
