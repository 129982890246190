import React from "react"

import { useForm } from "react-hook-form"

import Button from "../../Button"
import Spinner from "../../Spinner"
import {
  Form,
  Error,
  Fieldset,
  FormHeading,
  Field,
  Input,
  Label,
} from "../layout"

const EnterYourDetails = ({ onSubmit, suggestion, error, loading }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ defaultValues: { ...suggestion } })

  return (
    <Form onSubmit={handleSubmit(data => onSubmit(data))}>
      <FormHeading>Enter your details</FormHeading>
      <Fieldset>
        <Field>
          <Label htmlFor="authorName">Your name *</Label>
          <Input
            id="authorName"
            {...register("authorName", { required: true })}
          />
          {errors.authorName && <Error>Please enter your name.</Error>}
        </Field>
        <Field>
          <Label htmlFor="authorEmail">Your email address *</Label>
          <Input
            id="authorEmail"
            {...register("authorEmail", { required: true })}
          />
          {errors.authorEmail && (
            <Error>Please enter your email address.</Error>
          )}
        </Field>
      </Fieldset>
      {error && <Error>{error}</Error>}
      <Button type="submit" style={{ position: "relative" }}>
        {loading ? (
          <Spinner center={true} alt="Loading..." />
        ) : (
          "Submit for review"
        )}
      </Button>
    </Form>
  )
}

export default EnterYourDetails
