import React from "react"
import styled from "styled-components"
import { Tooltip } from "@reach/tooltip"
import "@reach/tooltip/styles.css"

const StyledTooltip = styled.div`
  border-color: ${props => props.theme.styles.cardShadow};
  background: ${props => props.theme.styles.pale};
  color: ${props => props.theme.styles.grey};
`

export default ({ label, children }) => (
  <Tooltip as={StyledTooltip} label={label}>
    {children}
  </Tooltip>
)
