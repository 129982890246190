import styled from "styled-components"

const InputIconButton = styled.button`
  position: absolute;
  right: 2px;
  bottom: 2px;
  background: none;
  height: 41px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  img {
    max-height: 20px;
    max-width: 20px;
  }
  &:hover {
    background: ${props => props.theme.styles.pale};
  }
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`

export default InputIconButton
