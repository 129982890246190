import React, { forwardRef, useContext } from "react"
import { useCombobox, useMultipleSelection } from "downshift"
import styled from "styled-components"
import { Controller } from "react-hook-form"

import { AppSettingsContext } from "../../../contexts/appSettingsContext"
import InputIconButton from "../../InputIconButton"
import { createProxyEnvironment } from "../../../lib/downshift"
import { Field, Input, Label, LabelHelpText } from "../layout"
import downArrow from "../down-arrow.svg"
import upArrow from "../up-arrow.svg"

const SelectedItem = styled.span`
  display: inline-flex;
  padding: 0.25rem 0.75rem;
  background: ${props => props.theme.styles.pale};
  gap: 0.5rem;
`

const RemoveSelectedItemButton = styled.span`
  cursor: pointer;
`

const SelectBox = styled.div`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid ${props => props.theme.styles.text};
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  &:focus-within {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
`

const SearchInputWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SearchInput = styled(Input)`
  flex-grow: 1;
  border: none;
  padding: 0;
  height: 25px;
  &:focus {
    outline: none;
  }
`

const InputButton = styled(InputIconButton)`
  position: static;
  height: 25px;
`

const Menu = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  background-color: #fff;
  position: absolute;
  z-index: 10000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
`

const MenuItem = styled.li`
  cursor: default;
  padding: 0 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 13px;
  font-family: Arial, sans-serif;
`

function getFilteredItems(allItems, selectedItems, inputValue) {
  const lowerCasedInputValue = inputValue.toLowerCase()

  return allItems.filter(item => {
    return (
      !selectedItems.includes(item) &&
      item.name.toLowerCase().includes(lowerCasedInputValue)
    )
  })
}

const MultiSelectDropdown = forwardRef(
  (
    {
      onChange,
      allItems,
      selectedItems,
      label,
      helpText,
      onBlur,
      placeholder = "Select some options",
    },
    ref
  ) => {
    const [inputValue, setInputValue] = React.useState("")
    const items = getFilteredItems(allItems, selectedItems, inputValue)
    const appSettings = useContext(AppSettingsContext)
    const environment = createProxyEnvironment(appSettings.shadowRoot)
    const {
      getSelectedItemProps,
      getDropdownProps,
      removeSelectedItem,
    } = useMultipleSelection({
      environment,
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            onChange(newSelectedItems)
            break
          default:
            break
        }
      },
    })
    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      selectedItem,
    } = useCombobox({
      environment,
      items,
      itemToString(item) {
        return item ? item.name : ""
      },
      defaultHighlightedIndex: 0, // after selection, highlight the first item.
      selectedItem: null,
      stateReducer(_state, actionAndChanges) {
        const { changes, type } = actionAndChanges

        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              ...(changes.selectedItem && {
                isOpen: true,
                highlightedIndex: 0,
                inputValue: "",
              }),
            }
          default:
            return changes
        }
      },
      onStateChange({
        inputValue: newInputValue,
        type,
        selectedItem: newSelectedItem,
      }) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            onChange([...selectedItems, newSelectedItem])
            if (typeof newInputValue !== "undefined") {
              setInputValue(newInputValue)
            }
            break

          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(newInputValue)

            break
          default:
            break
        }
      },
    })

    return (
      <Field>
        <Label {...getLabelProps()}>
          {label}
          {helpText && <LabelHelpText>{helpText}</LabelHelpText>}
        </Label>

        <SelectBox>
          {selectedItems.map(function renderSelectedItem(
            selectedItemForRender,
            index
          ) {
            return (
              <SelectedItem
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: selectedItemForRender,
                  index,
                })}
              >
                {selectedItemForRender.name}
                <RemoveSelectedItemButton
                  onClick={e => {
                    e.stopPropagation()
                    removeSelectedItem(selectedItemForRender)
                  }}
                >
                  &#10005;
                </RemoveSelectedItemButton>
              </SelectedItem>
            )
          })}
          <SearchInputWrapper>
            <SearchInput
              placeholder={placeholder}
              ref={ref}
              {...getInputProps({
                onBlur,
                ...getDropdownProps({ preventKeyAction: isOpen }),
              })}
            />
            <InputButton
              aria-label="toggle menu"
              type="button"
              data-no-autofocus
              {...getToggleButtonProps()}
            >
              <img src={isOpen ? upArrow : downArrow} alt="toggle menu" />
            </InputButton>
          </SearchInputWrapper>
        </SelectBox>
        <Menu
          style={{
            display: !(isOpen && items.length) ? "none" : null,
          }}
          {...getMenuProps()}
        >
          {isOpen &&
            items.map((item, index) => (
              <MenuItem
                style={{
                  backgroundColor:
                    highlightedIndex === index ? "#ebf2fe" : null,
                  fontWeight: selectedItem === item ? "bold" : null,
                }}
                key={`${item.value}${index}`}
                {...getItemProps({ item, index })}
              >
                <span>{item.name}</span>
              </MenuItem>
            ))}
        </Menu>
      </Field>
    )
  }
)

const MultiSelectDropdownController = ({
  id,
  control,
  allItems,
  label,
  helpText,
}) => {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const handleChange = selectedItems => {
          onChange(selectedItems.map(i => i.name).join("\n"))
        }
        const selectedNames = value?.split("\n").map(v => v.trim()) || []
        const selectedItems = allItems.filter(i =>
          selectedNames.find(
            n => n.toLowerCase() === i.name.toLowerCase().trim()
          )
        )
        return (
          <MultiSelectDropdown
            ref={ref}
            onChange={handleChange}
            onBlur={onBlur}
            selectedItems={selectedItems}
            allItems={allItems}
            label={label}
            helpText={helpText}
          />
        )
      }}
    />
  )
}

export { MultiSelectDropdownController }
export default MultiSelectDropdown
