import React, { useContext, useRef, useState } from "react"
import styled from "styled-components"
import { useCombobox } from "downshift"
import clearIcon from "./clear.svg"

import { fetchAutocompleteResults } from "../../lib/api"
import { AppSettingsContext } from "../../contexts/appSettingsContext"
import InputIconButton from "../InputIconButton"
import Tooltip from "../Tooltip"
import { createProxyEnvironment } from "../../lib/downshift"

const Label = styled.label`
  margin-bottom: 5px;
  display: inline-block;
`

const Input = styled.input`
  font-size: 1rem;
  padding: 10px;
  border: 2px solid ${props => props.theme.styles.text};
  display: block;
  width: 100%;
  height: 45px;
  padding-right: 45px;
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
  &::placeholder {
    opacity: 0.5;
  }
`

const Menu = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
`

const MenuItem = styled.li`
  cursor: default;
  padding: 0 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 13px;
  font-family: Arial, sans-serif;
`

const AutocompleteSearchInput = ({
  name,
  id,
  placeholder,
  onChange,
  onSelect,
  value,
}) => {
  const inputRef = useRef(null)
  const [items, setItems] = useState([])
  const appSettings = useContext(AppSettingsContext)
  const itemToString = i => i?.text || ""
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    environment: createProxyEnvironment(appSettings.shadowRoot),
    async onInputValueChange({ inputValue }) {
      onChange(inputValue)
      try {
        const results = await fetchAutocompleteResults(inputValue)
        setItems(results)
      } catch (e) {
        console.error(e)
      }
    },
    onStateChange({ type }) {
      if (
        type === useCombobox.stateChangeTypes.InputKeyDownEnter &&
        highlightedIndex === -1
      ) {
        onSelect({ text: value })
      }
    },
    onSelectedItemChange({ selectedItem }) {
      onSelect(selectedItem)
    },
    items,
    inputValue: value || "",
    itemToString,
  })

  const handleClear = () => {
    onChange("")
    selectItem(null)
    setItems([])
    onSelect()
    inputRef?.current?.focus()
  }

  return (
    <>
      <Label {...getLabelProps()}>Search</Label>
      <div>
        <Input
          id={id}
          name={name}
          placeholder={placeholder}
          {...getInputProps({
            ref: inputRef,
            onKeyDown: event => {
              if (event.key === "Home" || event.key === "End") {
                event.nativeEvent.preventDownshiftDefault = true
              }
            },
          })}
        />
        <Tooltip label="Clear your search">
          <InputIconButton
            {...getToggleButtonProps()}
            type="button"
            onClick={handleClear}
          >
            <img src={clearIcon} alt="Clear your search" />
          </InputIconButton>
        </Tooltip>
      </div>
      <Menu
        style={{
          display: isOpen ? "block" : "none",
        }}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <MenuItem
              style={{
                backgroundColor: highlightedIndex === index ? "#ebf2fe" : null,
              }}
              key={`${item.id}${index}`}
              {...getItemProps({
                item,
                index,
              })}
            >
              {itemToString(item)}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default AutocompleteSearchInput
