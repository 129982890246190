export const theme_scc = {
  cta: "#dc0050",
  ctaHover: "#a9003d",
  ctaActive: "#dc0050",

  link: "#002e6f",
  linkHover: "#006fdd",
  linkActive: "#006fdd",

  linkBackground: "rgba(44, 45, 132, 0.1)",
  textBackground: "rgba(33, 33, 33, 0.1)",

  text: "#212121",
  grey: "#606060",
  pale: "#eeeeee",
  offwhite: "#f6f0ef",
  white: "#ffffff",

  grey2: "#999999",

  cardShadow: "rgba(225,223,210, 0.6)",
  toggleColor: "#606060",

  green: "#9fc63b",
  focus: "#FFB81C",
  error: "#d51010",

  darkGreen: "#29330F",

  infoAlertBackground: "#dae9ff",
  warningAlertBackground: "#413402",

  maxWidth: "1140px",
  outerSpacing: "20px",
  resultsSpacing: "20px",
  breakpointS: "350px",
  breakpointM: "750px",
  breakpointL: "1140px",

  resultsAreaBg: "#ffffff",

  primary: "#002e6f",
  primaryCompanion: "#dc0050", // bright pink
  primaryText: "#222222",
  primaryHover: "#002e6f",
  primaryHoverText: "#222222",
}
