import React from "react"
import styled from "styled-components"
import A from "../A"

const Container = styled.div`
  margin-top: 25px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin-top: 35px;
    text-align: right;
  }
`

const Credits = () => (
  <Container>
    <A target="_blank" href="https://www.etchuk.com">
      Council Services Directory created by Etch
    </A>
  </Container>
)

export default Credits
