import React from "react"
import { Marker } from "@react-google-maps/api"
import marker from "./marker.svg"
import { normalizeQuerystring } from "../../lib/utils"

const ResultMarker = ({ service, location, navigate }) => {
  let { search } = location
  search = normalizeQuerystring(search)
  const url = `service/${service.id}${search}`
  return (
    <Marker
      key={service.id}
      onClick={() => {
        navigate(url)
      }}
      animation={window.google.maps.Animation.DROP}
      position={{
        lat: service.location.latitude,
        lng: service.location.longitude,
      }}
      title={service.name}
      icon={{
        url: marker,
        optimized: false,
        scaledSize: new window.google.maps.Size(40, 40),
      }}
    />
  )
}

export default ResultMarker
