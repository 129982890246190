import React from "react"

import { useForm } from "react-hook-form"

import Button from "../../Button"
import {
  Form,
  Error,
  Fieldset,
  FormHeading,
  RadioField,
  RadioInput,
  RadioLabel,
} from "../layout"

const ChooseSuggestionTypeForm = ({ onSubmit, suggestion }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: { ...suggestion },
  })

  return (
    <Form onSubmit={handleSubmit(data => onSubmit(data))}>
      <FormHeading>What kind of suggestion?</FormHeading>
      <Fieldset>
        <RadioField>
          <RadioInput
            id="suggestionTypeEdit"
            type="radio"
            autoFocus
            {...register("suggestionType", { required: true })}
            value="Edit"
          />
          <RadioLabel htmlFor="suggestionTypeEdit">
            Something is incorrect
          </RadioLabel>
        </RadioField>
        <RadioField>
          <RadioInput
            id="suggestionTypeAdd"
            type="radio"
            {...register("suggestionType", { required: true })}
            value="Edit"
          />
          <RadioLabel htmlFor="suggestionTypeAdd">
            I have information to add
          </RadioLabel>
        </RadioField>
        <RadioField>
          <RadioInput
            id="suggestionTypeClosed"
            type="radio"
            {...register("suggestionType", { required: true })}
            value="Closed"
          />
          <RadioLabel htmlFor="suggestionTypeClosed">
            The service is closed
          </RadioLabel>
        </RadioField>
        <RadioField>
          <RadioInput
            id="suggestionTypeOther"
            type="radio"
            {...register("suggestionType", { required: true })}
            value="Other"
          />
          <RadioLabel htmlFor="suggestionTypeOther">Something else</RadioLabel>
        </RadioField>

        {errors.suggestionType && (
          <Error>Please select one of the options.</Error>
        )}
      </Fieldset>
      <Button type="submit">Next</Button>
    </Form>
  )
}

export default ChooseSuggestionTypeForm
