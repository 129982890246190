import queryString from "query-string"

let controller
let autocompleteController

/**
 * These calls rely on
 * @param {*} query
 * @returns
 */
export const fetchSiteData = async query => {
  // TODO no point fetching all of these for all sites if they're not used
  return await Promise.allSettled([
    fetchData("vocabularies?detail=true"),
    fetchData("taxonomies"),
    fetchData("service_areas"),
  ])
    .then(data => {
      // console.log("returned from fatching all data", data)
      return data.map(results => {
        if (results.status === "fulfilled") {
          return results.value
        } else {
          return []
        }
      })
    })
    .catch(error => {
      // if there's an error, log it
      console.log(error)
      // return what the site expects so that filters just don't show
      return [...Array(4)].map(x => [])
    })
}

/**
 * Get service data
 * @param {*} query
 * @param {*} itemsPerPage
 * @returns
 */
export const fetchServiceData = async (query, itemsPerPage) => {
  controller?.abort() //cancel the previous request

  controller = new AbortController()

  const per_page = itemsPerPage || 20

  let {
    keywords,
    location,
    lat,
    lng,
    service_area,
    taxonomy_id,
    day,
    minimum_age,
    maximum_age,
    only,
    page,
    subset,
  } = queryString.parse(query)

  const res = await fetch(
    `${process.env.REACT_APP_API_HOST}/services?${queryString.stringify({
      keywords,
      location,
      lat,
      lng,
      taxonomy_id,
      service_area,
      day,
      minimum_age,
      maximum_age,
      only,
      page,
      per_page,
      subset,
    })}`,
    { signal: controller.signal }
  )
  return await res.json()
}

/**
 * Get service data
 * @param {*} search
 * @returns
 */
export const fetchAutocompleteResults = async search => {
  autocompleteController?.abort() //cancel the previous request

  autocompleteController = new AbortController()

  const res = await fetch(
    `${
      process.env.REACT_APP_API_HOST
    }/search/autocomplete?${queryString.stringify({
      search,
    })}`,
    { signal: autocompleteController.signal }
  )
  return await res.json()
}

/**
 * Fetching data from outpost itself vs the ol' static files method
 * @param {string} resource
 * @returns {}
 */
export const fetchData = async resource => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/${resource}`)
    return await res.json()
  } catch (err) {
    console.log(err)
    return []
  }
}

/**
 * Post service suggestion
 * @param {*} data
 * @returns
 */
export const postServiceData = async data => {
  return await fetch(`${process.env.REACT_APP_API_HOST}/services/suggestions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
}

/**
 * Update service suggestion
 * @param {*} data
 * @returns
 */
export const updateServiceData = async (serviceId, data) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_HOST}/services/${serviceId}/suggestions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
  return await res
}
