import React from "react"
import styled from "styled-components"

const Outer = styled.section`
  margin-bottom: 25px;

  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin-bottom: 0px;
  }
`

const Button = styled.button`
  padding: 10px 0px;
  text-align: left;
  color: ${props => props.theme.styles.link};
  font-size: 1rem;
  margin-top: 30px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin-top: 10px;
  }
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: underline;
  &:focus {
    outline: 3px solid ${props => props.theme.styles.focus};
  }
  &:hover {
    color: ${props => props.theme.styles.linkHover};
    text-decoration: none;
  }
  &:active {
    color: ${props => props.theme.styles.text};
  }
`

const ClearFilters = ({ setPage, filters }) => {
  const actionClearFilters = e => {
    e && e.preventDefault()

    setPage(1)

    filters.forEach(function (f) {
      f.clear.map((clr, i) => clr(f.clearValue[i]))
    })
  }

  return (
    <Outer>
      <Button onClick={actionClearFilters}>Clear all filters</Button>
    </Outer>
  )
}

export default ClearFilters
