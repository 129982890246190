import React from "react"

import styled, { createGlobalStyle } from "styled-components"
import Breadcrumbs from "../Breadcrumbs"

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
    line-height: 1.2;
  }

  *::-moz-focus-inner {
    border: 0 !important;

  }

  *::-moz-focusring {
    border: 0 !important;
  }

  p {
    line-height: 1.5;
    color: ${props => props.theme.styles.text};
  }
`

const Container = styled.div`
  font-size: initial;
  line-height: initial;
`

export const PageHeader = styled.div`
  padding: 30px ${props => props.theme.styles.outerSpacing};
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: 40px ${props => props.theme.styles.outerSpacing};
  }
`

const PageHeaderInner = styled.div`
  max-width: ${props => props.theme.styles.maxWidth};
  margin-left: auto;
  margin-right: auto;
`

export const PageTitle = styled.h1`
  color: ${props => props.theme.styles.primary};
  font-size: 1.75rem;
  margin-bottom: 30px;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    font-size: 2.625rem;
    margin-bottom: 40px;
    max-width: 80%;
  }
  @media screen and (min-width: ${props => props.theme.styles.breakpointL}) {
    max-width: 60%;
  }
`

const ResultsArea = styled.div`
  padding: 30px ${props => props.theme.styles.outerSpacing};
  background: ${props => props.theme.styles.pale};
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: 60px ${props => props.theme.styles.outerSpacing};
  }
`

const ResultsAreaFull = styled.div`
  padding: 20px ${props => props.theme.styles.outerSpacing} 30px;
  background: ${props => props.theme.styles.pale};
`

const ResultsAreaInner = styled.div`
  max-width: ${props => props.theme.styles.maxWidth};
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    display: flex;
    flex-direction: row;
  }
`

const Sidebar = styled.aside`
  position: relative;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    width: 300px;
    margin-right: 40px;
  }
  @media screen and (min-width: ${props => props.theme.styles.breakpointL}) {
    margin-right: 60px;
  }
`

const MainArea = styled.main`
  flex: 1;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    padding: ${props => props.theme.styles.resultsSpacing};
  }
`

export const ResultsHeader = styled.div`
  margin-bottom: 25px;
  *:first-child {
    margin-bottom: 15px;
  }
  @media screen and (min-width: ${props => props.theme.styles.breakpointS}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    *:first-child {
      margin-bottom: 0px;
    }
  }
`

export const Count = styled.p`
  color: ${props => props.theme.styles.grey};
  font-size: 0.9rem;
  margin-bottom: 0px;
  margin-right: 45px;
  flex: 1;
`

export const NoResults = styled.p`
  color: ${props => props.theme.styles.grey};
  text-align: center;
  font-size: 1.2rem;
  margin: 50px;
`

export const ResultsList = styled.ul`
  list-style: none;
`

export const ResultsFooter = styled.footer`
  margin-top: 25px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const PostResultsSection = styled.section`
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 2rem;
  background: #fff;
  @media screen and (min-width: ${props => props.theme.styles.breakpointM}) {
    margin-top: 60px;
  }

  h3 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 2rem;
  }
`

const Layout = ({
  headerComponents,
  sidebarComponents,
  mainContentComponents,
  scrollRef,
}) => {
  return (
    <Container>
      <GlobalStyle />
      {headerComponents && (
        <PageHeader role="search">
          <PageHeaderInner>
            <Breadcrumbs />
            <PageTitle>Find activities and organisations near you</PageTitle>
            <div id="main-content">{headerComponents}</div>
          </PageHeaderInner>
        </PageHeader>
      )}
      {sidebarComponents ? (
        <ResultsArea ref={scrollRef}>
          <ResultsAreaInner>
            {sidebarComponents && (
              <Sidebar id="sidebar" aria-label="sidebar">
                {sidebarComponents}
              </Sidebar>
            )}
            <MainArea id="results">{mainContentComponents}</MainArea>
          </ResultsAreaInner>
        </ResultsArea>
      ) : (
        <ResultsAreaFull ref={scrollRef}>
          <ResultsAreaInner>
            {sidebarComponents && (
              <Sidebar id="sidebar" aria-label="sidebar">
                {sidebarComponents}
              </Sidebar>
            )}
            <MainArea id="results">{mainContentComponents}</MainArea>
          </ResultsAreaInner>
        </ResultsAreaFull>
      )}
      {/* <Prefooter /> */}
    </Container>
  )
}

export default Layout
