export function createProxyEnvironment(shadowRoot) {
  const doc = shadowRoot.ownerDocument
  const properties = {
    document: doc,
    addEventListener: doc.addEventListener.bind(shadowRoot),
    removeEventListener: doc.removeEventListener.bind(shadowRoot),
    Node,
  }
  return new Proxy(shadowRoot, {
    get: (_, prop) => properties[prop],
  })
}
